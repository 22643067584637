import React, { useContext, useEffect, useState } from "react";
import { TheHeaderDefault } from "src/components/phase1/containers";
import Filter from "src/components/GiftRegistrationManagement/Filter";
import { keyHeaderFilter, titleHeaderFilter } from "src/util/data";
import useFilters from "src/hooks/useFilter";
import { DataGrid } from "@mui/x-data-grid";
import StickerAPI from "src/api/Managements/StickerAPI";
import { LoadingContext } from "src/providers/LoadingProvider";
import {
	Box,
	Button,
	FormControl,
	MenuItem,
	Pagination,
	Select,
	Stack,
	Typography,
} from "@mui/material";
import ModalAddGift from "src/components/GiftRegistrationManagement/ModalAddGift";
import moment from "moment";
import { Player } from "@lottiefiles/react-lottie-player";

export const GIFT_REGISTRATION_STATUS = {
	REGISTER: "REGISTER",
	DELETE: "DELETE",
};

const GiftRegistrationManagement = () => {
	const filterState = useFilters();
	const { LIMITED, filter, currentPage, spages } = filterState;
	const { showLoading, hideLoading } = useContext(LoadingContext);
	const [listItems, setListItems] = useState([]);
	const [isOpenModalAddGift, setIsOpenModalAddGift] = useState(false);
	const [isRefreshData, setIsRefreshData] = useState(false);
	const employee = JSON.parse(localStorage.getItem("user"));

	const columns = [
		{
			field: "no",
			headerName: "번호",
			headerAlign: "center",
			sortable: false,
			align: "center",
		},
		{
			field: "url",
			headerName: "선물 이미지 (파일명)",
			headerAlign: "center",
			sortable: false,
			align: "center",
			width: 250,
			minWidth: 250,
			flex: 1,
			renderCell: (params) => {
				if (!params?.value) return null;

				return (
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							gap: "10px",
							py: "5px",
						}}
					>
						<Player
							autoplay
							loop
							src={params?.value}
							style={{
								height: "50px",
								width: "50px",
							}}
						/>
						<Typography variant="body2" sx={{ textAlign: "center" }}>
							{params?.row?.sticker_name}
						</Typography>
					</Box>
				);
			},
		},
		{
			field: "star",
			headerName: "스타",
			headerAlign: "center",
			sortable: false,
			align: "center",
			width: 150,
			renderCell: (params) => {
				return params.value;
			},
		},
		{
			field: "status",
			headerName: "상태",
			headerAlign: "center",
			sortable: false,
			align: "center",
			width: 150,
			renderCell: (params) => {
				return params?.value ? "등록" : "삭제";
			},
		},
		{
			field: "created_at_unix_timestamp",
			headerName: "등록날짜",
			headerAlign: "center",
			sortable: false,
			align: "center",
			width: 200,
			renderCell: (params) => {
				return <>{moment(Number(params?.value)).format("YYYY-MM-DD HH:mm")}</>;
			},
		},
		{
			field: "updated_at_unix_timestamp",
			headerName: "삭제날짜",
			headerAlign: "center",
			sortable: false,
			align: "center",
			width: 200,
			renderCell: (params) => {
				if (params?.row?.status) {
					return "-";
				}

				return <>{moment(Number(params?.value)).format("YYYY-MM-DD HH:mm")}</>;
			},
		},
		{
			field: "employee_name",
			headerName: "처리계정",
			headerAlign: "center",
			sortable: false,
			align: "center",
			width: 150,
			renderCell: (params) => {
				return params.value;
			},
		},
		{
			field: "action",
			headerName: "비고",
			headerAlign: "center",
			sortable: false,
			align: "center",
			renderCell: (params) => {
				const status = params?.row?.status || false;
				const title = status ? "삭제" : "복구";
				const backgroundColor = status ? "#fb464c" : "#1976d2";
				const backgroundColorHover = status ? "#e5282e" : "#0862ba";

				return (
					<Button
						onClick={handleOnClickAction(!status, params?.row?.id)}
						sx={{
							minWidth: "auto",
							background: backgroundColor,
							color: "#FFFFFF",
							borderRadius: 0,
							padding: "2px 10px",
							"&:hover": {
								background: backgroundColorHover,
							},
						}}
					>
						{title}
					</Button>
				);
			},
		},
	];

	const handleChangePage = (_event, page) => {
		currentPage.set(page);
	};

	const handleOpenModalAddGift = () => {
		setIsOpenModalAddGift(true);
	};

	const handleOnClickAction = (status, id) => async () => {
		showLoading();
		try {
			const response = await StickerAPI.updateSticker(
				{
					status,
					employee_id: employee?.id,
				},
				id
			);

			if (response.code === 200) {
				setIsRefreshData(true);
			}
		} catch (error) {
			console.error("fetch api error:", error);
		} finally {
			hideLoading();
		}
	};

	const fetchAll = async () => {
		showLoading();
		try {
			const response = await StickerAPI.getListSticker(
				JSON.stringify([
					"$all",
					{
						employee: ["username", "id"],
					},
				]),
				JSON.stringify({
					...filter.value,
					type: "GIFT",
				}),
				LIMITED.value,
				currentPage.value
			);

			if (response.code === 200) {
				let _data = response?.results?.objects?.rows || [];
				const totalResp = response?.results?.objects?.count || 0;
				let pages = Math.max(Math.ceil(totalResp / LIMITED.value), 1);

				spages.set(pages);

				// format data
				const data = _data.map((item, idx) => {
					return {
						id: item?.id,
						no: totalResp - (idx + (currentPage.value - 1) * LIMITED.value),
						url: item?.url,
						sticker_name: item?.sticker_name,
						type: item?.type,
						status: item?.status,
						star: item?.price || 0,
						created_at_unix_timestamp: item?.created_at_unix_timestamp,
						updated_at_unix_timestamp: item?.updated_at_unix_timestamp,
						employee_name: item?.employee?.username,
					};
				});

				setListItems(data || []);
			}
		} finally {
			hideLoading();
			setIsRefreshData(false);
		}
	};

	useEffect(() => {
		fetchAll();
	}, [currentPage.value, LIMITED.value, filter.value]);

	// Get data list gift when isRefreshData is true
	useEffect(() => {
		if (isRefreshData) {
			fetchAll();
		}
	}, [isRefreshData]);

	useEffect(() => {
		currentPage.set(1);
	}, [filter.value]);

	return (
		<>
			<TheHeaderDefault
				title={titleHeaderFilter.GIFT_REGISTRATION_MANAGEMENT}
				Component={Filter}
				setFilter={filter.set}
				typeHeadeFilter={keyHeaderFilter.GIFT_REGISTRATION_MANAGEMENT}
				initValue={{
					statusGift: "ALL",
					username: "",
					dateTypeSelect: "created_at_unix_timestamp",
					time__data: [],
				}}
			/>
			<Stack direction="row" justifyContent="end" alignItems="center" gap="10px" mb="15px">
				<Button
					variant="contained"
					sx={{ background: "#3B3B3B", width: 141, height: 42, borderRadius: "0.5rem" }}
					onClick={handleOpenModalAddGift}
				>
					등록
				</Button>
				<FormControl sx={{ height: "42px" }}>
					<Select
						className="select"
						value={LIMITED.value}
						sx={{
							"& legend": { display: "none" },
							"& fieldset": { top: 0 },
						}}
						onChange={(e) => LIMITED.set(e.target.value)}
					>
						<MenuItem value={10}>10</MenuItem>
						<MenuItem value={20}>20</MenuItem>
						<MenuItem value={50}>50</MenuItem>
						<MenuItem value={100}>100</MenuItem>
					</Select>
				</FormControl>
			</Stack>
			<DataGrid
				sx={{
					width: "100%",
					"&.MuiDataGrid-root": {
						"& .MuiDataGrid-columnHeader": {
							background: "rgba(0, 0, 0, 0.04)",
							fontWeight: 700,
							"&:focus": {
								outline: "none",
							},
							"& .MuiDataGrid-columnHeaderTitle": {
								fontWeight: 700,
							},
						},
						"& .MuiDataGrid-cell": {
							"&:focus": {
								outline: "none",
							},
						},
					},
				}}
				autoHeight
				disableExtendRowFullWidth
				disableColumnFilter
				disableSelectionOnClick
				disableColumnMenu
				hideFooter
				showCellRightBorder
				showColumnRightBorder
				columns={columns}
				rows={listItems || []}
				checkboxSelection={false}
				headerHeight={50}
				getRowHeight={() => 60}
			/>
			<div className="pagination">
				<Pagination
					count={spages.value}
					page={currentPage.value}
					variant="outlined"
					shape="rounded"
					onChange={handleChangePage}
				/>
			</div>

			{isOpenModalAddGift && (
				<ModalAddGift
					open={isOpenModalAddGift}
					onClose={() => setIsOpenModalAddGift(false)}
					onSuccess={() => setIsRefreshData(true)}
				/>
			)}
		</>
	);
};

export default GiftRegistrationManagement;
