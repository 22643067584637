import { useState } from "react";

const useFilters = () => {
	const states = {
		currentPage: useState(1),
		spages: useState(1),
		LIMITED: useState(10),
		reload: useState(0),
		filter: useState({}),
	};

	return Object.fromEntries(Object.entries(states).map(([k, [value, set]]) => [k, { value, set }]));
};

export default useFilters;
