import Api from "./Api";
import axiosClient from "./axiosClient";

export default class useStarProfileApi extends Api {
	getAllPost(page, limit, filter) {
		const url = `${this.HOSTNAME}/post/admin/get_list_cms?fields=["$all",{"user":["nickname","identified_id","sex","id",{"$filter":{"id":{"$ne":null}}}]},{"usage_star_post":["id","created_at_unix_timestamp",{"sender":["$all"]}]},{"secret_album": ["$all"]}]&filter=${filter}&page=${page}&limit=${limit}&order=[["created_at","desc"]]`;
		return axiosClient.get(url);
	}
	getAllPostV2(page, limit, filter) {
		const url = `${this.HOSTNAME}/post/admin/get_list_cms?fields=["$all",{"user":["nickname","identified_id","sex","id",{"$filter":{"id":{"$ne":null}}}]},{"post_medias":["$all"]},{"post_subscribe":["$all"]}]&filter=${filter}&page=${page}&limit=${limit}&order=[["created_at","desc"]]`;
		return axiosClient.get(url);
	}
}
