// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NormalToCeleb_wrapper__n6Hyb {
  display: flex;
  justify-content: center;
}
.NormalToCeleb_wrapper__n6Hyb .NormalToCeleb_container__1sfSd {
  width: 25%;
  display: flex;
  justify-content: space-between;
}
.NormalToCeleb_wrapper__n6Hyb .NormalToCeleb_container__1sfSd .NormalToCeleb_inputPhone__7SLYT {
  width: 70%;
}`, "",{"version":3,"sources":["webpack://./src/components/phase2/userProfileDetail/NormalToCeleb.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;AACF;AACE;EACE,UAAA;EACA,aAAA;EACA,8BAAA;AACJ;AACI;EACE,UAAA;AACN","sourcesContent":[".wrapper {\n  display: flex;\n  justify-content: center;\n\n  .container {\n    width: 25%;\n    display: flex;\n    justify-content: space-between;\n\n    .inputPhone {\n      width: 70%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `NormalToCeleb_wrapper__n6Hyb`,
	"container": `NormalToCeleb_container__1sfSd`,
	"inputPhone": `NormalToCeleb_inputPhone__7SLYT`
};
export default ___CSS_LOADER_EXPORT___;
