import axiosClient from "./axiosClient";
const token = JSON.parse(localStorage.getItem("token"));
const HOSTNAME = process.env.REACT_APP_API;
const fields = 'fields=["$all"]';
const table = "system_setting";

const Api = {
	getAll: (page, limit, filter) => {
		const url = `${HOSTNAME}/${table}?${fields}&filter={"type":"DOOL_REGISTER"}&order=[["created_at","desc"]]`;
		return axiosClient.get(url);
	},

	update: (body, id) => {
		const url = `${HOSTNAME}/${table}/${id}`;
		return axiosClient.put(url, body, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
	},
};

export default Api;
