export const exportResults = (res) => res.results;

export const convertParams = (params) => {
	return Object.fromEntries(
		Object.entries(params).map(([key, value]) => [key, JSON.stringify(value)])
	);
};

export const getToken = () => {
	const token = localStorage.getItem("token");
	if (token) {
		return JSON.parse(token);
	}
	return null;
};
