/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from "react";
import * as FileSaver from "file-saver";
import { Box, Button, Modal, Stack } from "@mui/material";
import * as XLSX from "xlsx";
const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	bgcolor: "background.paper",
	border: "2px solid #000",
	boxShadow: 24,
	p: 4,
	borderRadius: 5,
};
const ExportCSV = ({ csvData, fileName, total, setLimit, setItemPrint }) => {
	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const fileType =
		"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
	const fileExtension = ".xlsx";
	const exportToCSV = (csvData, fileName) => {
		const ws = XLSX.utils.json_to_sheet(csvData);
		const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
		const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
		const data = new Blob([excelBuffer], { type: fileType });

		FileSaver.saveAs(data, fileName + fileExtension);
		setItemPrint([]);
	};
	const handleCheck = () => {
		if (total > 500) {
			handleOpen();
		} else {
			setLimit(total);
		}
	};
	useEffect(() => {
		if (csvData.length > 1) {
			exportToCSV(csvData, fileName);
		}
	}, [csvData]);

	return (
		<>
			<Button
				variant="contained"
				sx={{ background: "#3B3B3B", width: 141, height: 42, borderRadius: "0.5rem" }}
				onClick={handleCheck}
			>
				엑셀 다운로드
			</Button>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<Stack
						sx={{ textAlign: "center", fontSize: 20 }}
						alignContent="center"
						justifyContent="center"
						spacing={1}
					>
						<div style={{ color: "red" }}>경고</div>
						<div>데이터가 500개가 넘습니다. </div>
						<div>다운로드시 로딩이 느려지고, 오류가 발생 될 수 있으니 주의해 주세요.</div>
						<div style={{ fontWeight: 700 }}>다운로드를 계속 진행하시겠습니까?</div>
					</Stack>
					<Stack direction="row" sx={{ width: "100%", marginTop: 3 }} justifyContent="space-evenly">
						<Button
							variant="contained"
							color="success"
							onClick={() => {
								setLimit(total);
								handleClose();
							}}
						>
							확인
						</Button>
						<Button variant="contained" color="error" onClick={handleClose}>
							취소
						</Button>
					</Stack>
				</Box>
			</Modal>
		</>
	);
};

export default ExportCSV;
