import { getToken } from "src/util";
import axiosClient from "./axiosClient";
const HOSTNAME = process.env.REACT_APP_API;
const fields = 'fields=["$all", {"celeb": ["$all"]}, {"pack_sub": ["$all"]}]';
const table = "asp_subscriber";

const url = `${HOSTNAME}/${table}`;
const PluskitPackageListASPApi = {
	getAll: (page, limit, filter) => {
		return axiosClient.get(
			`${url}/admin/get_list_cms?${fields}&page=${page}&filter=${filter}&limit=${limit}&order=[["register_at_unix_timestamp", "desc"]]`
		);
	},
	update: (body, id) => {
		return axiosClient.put(`${url}/${id}`, body, {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		});
	},
};

export default PluskitPackageListASPApi;
