import axiosClient from "./axiosClient";
const token = JSON.parse(localStorage.getItem("token"));

export default class Api {
	HOSTNAME = process.env.REACT_APP_API;

	table = "";
	fields = "";
	token = "";
	axiosClient = "";
	constructor(fields, table) {
		this.fields = fields;
		this.table = table;
		this.token = token;
		this.axiosClient = axiosClient;
	}
	getAll(page = 1, limit = 20, filter, order) {

    	const url = `${this.HOSTNAME}/${this.table}/admin/get_list_cms?${
			this.fields
		}&filter=${filter}&page=${page}&limit=${limit}&order=[${
			order || '["created_at_unix_timestamp","desc"]'
		}]`;
		return axiosClient.get(url);
	}
	update(body, id) {
		const url = `${this.HOSTNAME}/${this.table}/${id}`;
		return axiosClient.put(url, body, {
			headers: {
				Authorization: `Bearer ${this.token}`,
			},
		});
	}
	delete(id) {
		const url = `${this.HOSTNAME}/${this.table}/${id}`;
		return axiosClient.delete(url, {
			headers: {
				Authorization: `Bearer ${this.token}`,
			},
		});
	}
	add(body) {
		const url = `${this.HOSTNAME}/${this.table}`;
		return axiosClient.post(url, body, {
			headers: {
				Authorization: `Bearer ${this.token}`,
			},
		});
	}
	findOne(id) {
		const url = `${this.HOSTNAME}/${this.table}/${id}?fields=["$all"]`;
		return axiosClient.get(url, {
			headers: {
				Authorization: `Bearer ${this.token}`,
			},
		});
	}
	updateMulti(body, filter) {
		const url = `${this.HOSTNAME}/${this.table}/admin/update_multi?filter={"id" : ${JSON.stringify(
			filter
		)}}`;
		return axiosClient.put(url, body, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
	}
}
