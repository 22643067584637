import { jwtDecode } from "jwt-decode";
import React, { Suspense, useMemo } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./app.css";
import { icons } from "./assets/icons";
import { TheLayout } from "./components/phase1/containers";
import { LoadingProvider } from "./providers/LoadingProvider";
import { UserProvider } from "./providers/UserProvider";
import { AlertProvider } from "./providers/AlertProvider";
import routes, { routesAntiKdisk, routesContentManager, routesFansomeASP } from "./routes";
import "./scss/style.scss";
import "@coreui/coreui/scss/coreui.scss";
import { getAdminType } from "./util/Utils";
import { showError } from "./util/error";
import { adminType } from "src/util/data";
import { getToken } from "./util";

React.icons = icons;
const Login = React.lazy(() => import("./Pages/Login"));
const ListUserASPLog = React.lazy(() => import("./Pages/UserManagementASP/ListUserASPLog"));

function App() {
	const employee = JSON.parse(localStorage.getItem("user"));
	const employeeType = getAdminType(employee?.type, employee?.username);
	const token = getToken();
	const tokenDecode = token ? jwtDecode(token) : "";
	let expired = false;

	const isAspAdmin = useMemo(() => {
		return employeeType === adminType.ADMIN_ASP || employeeType === adminType.SUPER_ADMIN_ASP;
	}, [employeeType]);

	const isContentManager = useMemo(() => {
		return employeeType === adminType.OPERATOR || employeeType === adminType.CONTENT_MANAGER;
	}, [employeeType]);

	const isFansomeASPAdmin = useMemo(() => {
		return employeeType === adminType.ADMIN_FANSOME_ASP;
	}, [employeeType]);

	if (new Date(tokenDecode.exp).getTime() <= Date.now()) {
		expired = true;
	}
	if (expired) {
		localStorage.clear();
		showError("Your login session has been expired!");
	}

	const getRouterWeb = () => {
		if (isAspAdmin) {
			return routesAntiKdisk;
		}
		if (isContentManager) {
			return routesContentManager;
		}
		if (isFansomeASPAdmin) {
			return routesFansomeASP;
		}
		return routes;
	}

	const loading = (
		<div className="pt-3 text-center">
			<div className="sk-spinner sk-spinner-pulse">
				...로딩 중
			</div>
		</div>
	);

	const PrivateRoute = ({ children }) => {
		const isAuthenticated = useSelector((state) => state.employee.length > 0 || localStorage.getItem("user"));
		return isAuthenticated ? <>{children}</> : <Navigate to="/login" />;
	};

	return (
		<div>
			<BrowserRouter basename="/">
				<AlertProvider>
					<LoadingProvider>
						<UserProvider>
							<TheLayout>
								<Suspense fallback={loading}>
									<Routes>
										<Route path="/listUserASPLog" element={<ListUserASPLog />} />
										<Route path="/login" element={<Login />} />
										{getRouterWeb().map((route, index) => (
											<React.Fragment key={index}>
												<Route path={route.path} element={<PrivateRoute children={route.element} />} />
											</React.Fragment>
										))}
									</Routes>
								</Suspense>
							</TheLayout>
						</UserProvider>
					</LoadingProvider>
				</AlertProvider>
			</BrowserRouter>
		</div>
	);
}

export default App;
