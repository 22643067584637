// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-color-dark {
	color: black !important;
}
body > input {
	color: black !important;
}

* > P {
	margin: 0;
}

body {
	color: black;
	background-color: white;
}
/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */

input[type="number"] {
	-moz-appearance: textfield;
}

.table-cell-trucate {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.custom-input {
	background: #fafafa;
	border: 1px solid #e1e1e1;
	border-radius: 10px;
	padding: 10px;
}
.custom-input-disabled {
	background: #cacaca;
	border: 1px solid #e1e1e1;
	border-radius: 10px;

	padding: 10px;
}

.swal2-container.swal2-backdrop-show,
.swal2-container.swal2-noanimation {
	z-index: 11000;
}

textarea {
	resize: none;
}

html {
	box-sizing: border-box;
	font-size: 16px;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
	margin: 0 !important;
	padding: 0;
	font-weight: normal;
	-webkit-font-smoothing: antialiased;
}

ol,
ul {
	list-style: none;
}

img {
	max-width: 100%;
	height: auto;
}
`, "",{"version":3,"sources":["webpack://./src/app.css"],"names":[],"mappings":"AAAA;CACC,uBAAuB;AACxB;AACA;CACC,uBAAuB;AACxB;;AAEA;CACC,SAAS;AACV;;AAEA;CACC,YAAY;CACZ,uBAAuB;AACxB;AACA,gCAAgC;;AAEhC;;CAEC,wBAAwB;CACxB,SAAS;AACV;;AAEA,YAAY;;AAEZ;CACC,0BAA0B;AAC3B;;AAEA;CACC,mBAAmB;CACnB,gBAAgB;CAChB,uBAAuB;AACxB;;AAEA;CACC,mBAAmB;CACnB,yBAAyB;CACzB,mBAAmB;CACnB,aAAa;AACd;AACA;CACC,mBAAmB;CACnB,yBAAyB;CACzB,mBAAmB;;CAEnB,aAAa;AACd;;AAEA;;CAEC,cAAc;AACf;;AAEA;CACC,YAAY;AACb;;AAEA;CACC,sBAAsB;CACtB,eAAe;AAChB;;AAEA;;;;;;;;;;CAUC,oBAAoB;CACpB,UAAU;CACV,mBAAmB;CACnB,mCAAmC;AACpC;;AAEA;;CAEC,gBAAgB;AACjB;;AAEA;CACC,eAAe;CACf,YAAY;AACb","sourcesContent":[".text-color-dark {\n\tcolor: black !important;\n}\nbody > input {\n\tcolor: black !important;\n}\n\n* > P {\n\tmargin: 0;\n}\n\nbody {\n\tcolor: black;\n\tbackground-color: white;\n}\n/* Chrome, Safari, Edge, Opera */\n\ninput::-webkit-outer-spin-button,\ninput::-webkit-inner-spin-button {\n\t-webkit-appearance: none;\n\tmargin: 0;\n}\n\n/* Firefox */\n\ninput[type=\"number\"] {\n\t-moz-appearance: textfield;\n}\n\n.table-cell-trucate {\n\twhite-space: nowrap;\n\toverflow: hidden;\n\ttext-overflow: ellipsis;\n}\n\n.custom-input {\n\tbackground: #fafafa;\n\tborder: 1px solid #e1e1e1;\n\tborder-radius: 10px;\n\tpadding: 10px;\n}\n.custom-input-disabled {\n\tbackground: #cacaca;\n\tborder: 1px solid #e1e1e1;\n\tborder-radius: 10px;\n\n\tpadding: 10px;\n}\n\n.swal2-container.swal2-backdrop-show,\n.swal2-container.swal2-noanimation {\n\tz-index: 11000;\n}\n\ntextarea {\n\tresize: none;\n}\n\nhtml {\n\tbox-sizing: border-box;\n\tfont-size: 16px;\n}\n\nbody,\nh1,\nh2,\nh3,\nh4,\nh5,\nh6,\np,\nol,\nul {\n\tmargin: 0 !important;\n\tpadding: 0;\n\tfont-weight: normal;\n\t-webkit-font-smoothing: antialiased;\n}\n\nol,\nul {\n\tlist-style: none;\n}\n\nimg {\n\tmax-width: 100%;\n\theight: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
