import { Box, Button, Modal, styled, Typography } from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useContext, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { Player } from "@lottiefiles/react-lottie-player";
import { LoadingContext } from "src/providers/LoadingProvider";
import StickerAPI from "src/api/Managements/StickerAPI";

const BoxContentStyled = styled(Box)(() => ({
	position: "absolute",
	content: '""',
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "350px",
	background: "#fff",
	borderRadius: "10px",
}));

const BoxFieldStyled = styled(Box)(() => ({
	display: "flex",
	alignItems: "center",
	gap: "10px",
	marginBottom: "15px",
	"& label": {
		margin: 0,
		minWidth: "80px",
		fontWeight: "bold",
		fontSize: "16px",
	},
	"& input": {
		width: "100%",
		background: "#fafafa",
		border: "1px solid #e1e1e1",
		borderRadius: "4px",
		minHeight: "35px",
		padding: "4px 10px",
		"&:focus": {
			outline: "none",
			borderColor: "#e1e1e1",
		},
	},
}));

const ButtonAddFileStyled = styled(Button)(() => ({
	width: "100%",
	boxShadow: "none",
	color: "#333",
	background: "#d3e7ff",
	padding: "10px",
	border: "1px dashed #8fd9ea",
	borderRadius: "4px",
	gap: "5px",
	"&:hover": {
		background: "#d3e7ff",
		border: "1px dashed #8fd9ea",
		boxShadow: "none",
		outline: "none",
	},
}));

const BoxPlayerStyled = styled(Box)(() => ({
	display: "flex",
	gap: "10px",
	alignItems: "center",
	marginBottom: "15px",
	"& label": {
		fontSize: "14px",
		color: "#333",
		fontWeight: "500",
		display: "block",
	},
}));

const ModalAddGift = ({ open, onClose, onSuccess }) => {
	const fileInputRef = React.useRef(null);
	const { showLoading, hideLoading } = useContext(LoadingContext);
	const [isShowMessageError, setIsShowMessageError] = useState(false);
	const initialValues = {
		fileName: "",
		star: "",
		file: null,
	};

	// Function to validate number
	const validateNumber = (value) => {
		let error;
		if (!value) {
			error = "필수 입력 항목입니다.";
		} else if (isNaN(value)) {
			error = "숫자만 입력 가능합니다.";
		}
		return error;
	};

	// Function to handle file change
	const handleFileChange = (event, setFieldValue) => {
		const file = event.target.files[0];

		if (file.type !== "application/json") {
			setFieldValue("fileName", "");
			setFieldValue("file", null);
			setIsShowMessageError(true);
			return;
		}

		// Set file to formik
		setFieldValue("fileName", file.name);
		setFieldValue("file", file);
		setIsShowMessageError(false);
	};

	// Function to handle form submit
	const handleSubmit = async (values) => {
		if (!values.file || !values.fileName || !values.star) return;

		const originalFile = values.file;
		const formData = new FormData();

		const updatedFile = new File([originalFile], `${values.fileName}`, {
			type: originalFile.type,
			lastModified: originalFile.lastModified,
		});

		formData.append("sticker_file", updatedFile);
		formData.append("price", values.star);
		formData.append("type", "GIFT");

		showLoading();
		try {
			const response = await StickerAPI.createSticker(formData);

			if (response.code === 200) {
				onClose();
				onSuccess();
			}
		} catch (error) {
			console.error("fetch api error:", error);
		} finally {
			hideLoading();
		}
	};

	return (
		<Modal
			open={open}
			onClose={onClose}
			aria-labelledby="modal-title"
			aria-describedby="modal-description"
		>
			<BoxContentStyled>
				{/* Header */}
				<Box position="relative" p="10px 15px">
					<Typography
						variant="h6"
						component="h2"
						textAlign="center"
						sx={{
							color: "#333",
							fontWeight: "bold",
							fontSize: "24px",
						}}
					>
						선물 등록
					</Typography>
					<Button
						onClick={onClose}
						sx={{
							position: "absolute",
							content: '""',
							right: "10px",
							top: "10px",
							padding: "4px",
							width: "28px",
							height: "28px",
							minWidth: "auto",
							color: "#333",
						}}
					>
						<CloseIcon />
					</Button>
				</Box>

				{/* Content */}
				<Box p="30px 15px 15px">
					<Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit}>
						{(formikProps) => {
							// Check if button submit is disabled
							const isDisabledButtonSubmit = (() => {
								if (
									formikProps.values.fileName === "" ||
									formikProps.values.star === "" ||
									formikProps.values.file === null ||
									formikProps.errors.star
								) {
									return true;
								}
								return false;
							})();

							return (
								<Form>
									<BoxFieldStyled>
										<label htmlFor="fileName">선물 파일</label>
										<Field
											name="fileName"
											placeholder="파일을 선택하세요"
											disabled={!formikProps.values.file}
										/>
									</BoxFieldStyled>
									<Box mb="15px">
										<ButtonAddFileStyled
											type="button"
											variant={"contained"}
											onClick={() => fileInputRef.current.click()}
										>
											<CloudUploadOutlinedIcon />
											찾아보기
										</ButtonAddFileStyled>
										{isShowMessageError && (
											<Typography
												variant="body2"
												sx={{
													textAlign: "left",
													color: "#F44336",
													fontSize: "12px",
													marginTop: "5px !important",
												}}
											>
												Json파일만 적용 가능합니다.
											</Typography>
										)}
									</Box>
									<input
										type="file"
										accept=".json"
										style={{ display: "none" }}
										ref={fileInputRef}
										onChange={(event) => handleFileChange(event, formikProps.setFieldValue)}
									/>
									{/* Show filed */}
									{formikProps.values.file && (
										<BoxPlayerStyled>
											<Player
												autoplay
												loop
												src={URL.createObjectURL(formikProps.values.file)}
												style={{
													height: "50px",
													width: "50px",
												}}
											/>
											<label>{formikProps.values.fileName}</label>
										</BoxPlayerStyled>
									)}

									<BoxFieldStyled>
										<label htmlFor="star">스타</label>
										<Box flex={1}>
											<Field
												name="star"
												placeholder="입력해주세요"
												type="text"
												autoComplete="off"
												validate={validateNumber}
											/>
											<ErrorMessage
												name="star"
												component="div"
												style={{
													color: "#F44336",
													fontSize: "12px",
													marginTop: "5px",
												}}
											/>
										</Box>
									</BoxFieldStyled>

									<Button
										type="submit"
										disabled={isDisabledButtonSubmit}
										sx={{
											width: "45%",
											height: "35px",
											fontSize: "16px",
											fontWeight: "bold",
											borderRadius: "6px",
											margin: "25px auto 0",
											display: "block",
											background: "#333",
											color: "#fff",
											"&:disabled": {
												opacity: 0.5,
												color: "#fff",
											},
											"&:hover": {
												background: "#333",
											},
										}}
									>
										등록
									</Button>
								</Form>
							);
						}}
					</Formik>
				</Box>
			</BoxContentStyled>
		</Modal>
	);
};

export default ModalAddGift;
