import React, { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import ExportCSV from "./ExportCSV";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Pagination from "@mui/material/Pagination";
import CloseIcon from "@mui/icons-material/Close";
import { USersInConverSation } from "src/util/Utils";

import "moment/locale/ko";

import "./style.css";
import { convertTimeZone } from "src/util/convert";
const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 578,
	bgcolor: "background.paper",
	border: "1px solid #E6ECF3",
	boxShadow: 24,
};
const divStyle = {
	whiteSpace: "nowrap",
	overflow: "hidden",
	textOverflow: "ellipsis",
	width: "30%",
	fontWeight: 700,
	color: "#000",
	textAlign: "center",
	font: "normal normal 600 16px/41px Pretendard",
};
const SenderMessage = ({ open, handleClose, data, type }) => {
	const listUser = type ? data : USersInConverSation(data);
	const [page, setPage] = useState(1);
	const handleChange = (_, value) => {
		setPage(value);
	};
	const limit = 10;
	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				open={open}
				onClose={handleClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={open}>
					<Box sx={style}>
						<div className="chatMessHeader">
							<p>발송인원({listUser.length})</p>
							<CloseIcon className="closeBtn" onClick={handleClose} />
						</div>

						<div className="sender-container">
							<div className="sender-header">
								<p>총{listUser.length}건</p>
								<div className="sender-header-btn">
									<ExportCSV csvData={data} fileName="user in conversation" />
								</div>
							</div>

							<div className="sender-table">
								<div className="sender-table-header3">
									<div style={{ ...divStyle, color: "#868686", fontSize: "14px" }}>회원 아이디</div>
									<div style={{ ...divStyle, color: "#868686", fontSize: "14px" }}>회원 닉네임</div>
									<div style={{ ...divStyle, color: "#868686", fontSize: "14px" }}>구매날짜</div>
									{/* <p>보유 스타</p> */}
								</div>
								{listUser.map((item, id) => {
									const { sender } = item;

									return (
										page - 1 <= Math.floor(id / limit) &&
										Math.floor(id / limit) < page && (
											<div key={id} className="sender-table-header3 sender-table-body">
												<div style={{ ...divStyle }}>{sender && sender.identified_id}</div>
												<div style={{ ...divStyle }}>{sender && sender.nickname}</div>
												<div style={{ ...divStyle }}>
													{convertTimeZone(+item?.created_at_unix_timestamp)}
												</div>
											</div>
										)
									);
								})}
							</div>
							<div className="sender-pagi">
								<Pagination
									count={Math.max(Math.ceil(listUser.length / limit), 1)}
									variant="outlined"
									shape="rounded"
									onChange={handleChange}
								/>
							</div>
						</div>
					</Box>
				</Fade>
			</Modal>
		</div>
	);
};

export default SenderMessage;
