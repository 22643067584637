import axios from "axios";
import queryString from "query-string";
import { getToken } from "src/util";

const axiosClient = axios.create({
	baseURL: process.env.REACT_APP_API, // Set baseURL during client creation
	headers: {
		"Content-Type": "application/json",
	},
	paramsSerializer: (params) => queryString.stringify(params),
});

// Add a request interceptor to always attach the latest token
axiosClient.interceptors.request.use(
	async (config) => {
		const token = getToken(); // Get the latest token dynamically
		if (token) {
			config.headers.Authorization = `Bearer ${token}`; // Attach token to headers
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

// Add a response interceptor to handle responses globally
axiosClient.interceptors.response.use(
	(response) => {
		if (response && response.data) {
			return response.data;
		}
		return response;
	},
	(error) => {
		// Handle errors globally
		return Promise.reject(error);
	}
);

export default axiosClient;
