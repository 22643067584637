import axiosClient from "./axiosClient";
const token = JSON.parse(localStorage.getItem("token"));
const HOSTNAME = process.env.REACT_APP_API;
const fields =
	'fields=["$all",{"employee" : ["fullname","username"]},{"user" : ["nickname","identified_id","user_type"]}]';
const table = "inquiry";
const fieldsASP =
	'fields=["$all",{"employee" : ["fullname","username"]},{"user" : ["nickname","identified_id","user_type",{"asp_user":["$all"]}]}]';

const Api = {
	/**
	 *
	 *
	 * FILTER CRUD
	 *
	 */

	filter: (text, page, limit, column, state) => {
		let url = "";
		let filedFullname = "";
		if (state && column) {
			filedFullname = `fields=["$all",{"user":["nickname",{"$filter": {"nickname": {"$iLike": "%25${text.trim()}%25"} } } ]}]`;
			url = `${HOSTNAME}/${table}?${
				filedFullname ? filedFullname : fields
			}&page=${page}&limit=${limit}&filter={"state":"${state}"}&order=[["created_at","desc"]]`;
		} else if (state) {
			url = `${HOSTNAME}/${table}?${
				filedFullname ? filedFullname : fields
			}&page=${page}&limit=${limit}&filter={"state":"${state}"}&order=[["created_at","desc"]]`;
		} else {
			if (column === "nickname")
				filedFullname = `fields=["$all",{"user":["nickname",{"$filter": {"nickname": {"$iLike": "%25${text.trim()}%25"} } } ]}]`;
			url = `${HOSTNAME}/${table}?${
				filedFullname ? filedFullname : fields
			}&page=${page}&limit=${limit}&order=[["created_at","desc"]]`;
		}

		return axiosClient.get(url);
	},

	findAll: (page, limit) => {
		const url = `${HOSTNAME}/${table}/admin/get_list_cms?${fields}&page=${page}&limit=${limit}&order=[["created_at", "desc"],["processed_at_unix_timestamp", "desc"]]`;
		return axiosClient.get(url);
	},

	findOne: (id) => {
		const url = `${HOSTNAME}/${table}/${id}?${fields}`;
		return axiosClient.get(url, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
	},
	getAll: (page, limit, filter) => {
		const url = `${HOSTNAME}/${table}/admin/get_list_cms?${fields}&filter=${filter}&page=${page}&limit=${limit}&order=[["created_at", "desc"]]`;
		return axiosClient.get(url);
	},

	add: (body) => {
		const url = `${HOSTNAME}/${table}`;
		return axiosClient.post(url, body, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
	},

	update: (body, id) => {
		const url = `${HOSTNAME}/${table}/${id}`;
		return axiosClient.put(url, body, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
	},
	AdminReply: (body, id) => {
		const url = `${HOSTNAME}/${table}/${id}/admin_reply`;
		return axiosClient.post(url, body, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
	},
	deleteMany: (id) => {
		const url = `${HOSTNAME}/${table}/?items=${id}`;
		return axiosClient.delete(url, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
	},

	delete: (id) => {
		const url = `${HOSTNAME}/${table}/${id}`;
		return axiosClient.delete(url, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
	},
	getAllASP: (page, limit, filter) => {
		const url = `${HOSTNAME}/${table}/admin/get_list_cms?${fieldsASP}&filter=${filter}&page=${page}&limit=${limit}&order=[["created_at", "desc"]]`;
		return axiosClient.get(url);
	},
	findOneASP: (id) => {
		const url = `${HOSTNAME}/${table}/${id}?${fieldsASP}`;
		return axiosClient.get(url, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
	},
	AdminReplyASP: (body, id) => {
		const url = `${HOSTNAME}/${table}/${id}`;
		return axiosClient.post(url, body, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
	},
};

export default Api;
