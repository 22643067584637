import React from "react";
import * as FileSaver from "file-saver";
import { Button } from "@mui/material";
import * as XLSX from "xlsx";

const ExportCSV = ({ csvData, fileName }) => {
	const fileType =
		"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
	const fileExtension = ".xlsx";
	const exportToCSV = (csvData, fileName) => {
		const ws = XLSX.utils.json_to_sheet(csvData);
		const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
		const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
		const data = new Blob([excelBuffer], { type: fileType });

		FileSaver.saveAs(data, fileName + fileExtension);
	};
	return (
		<Button
			variant="contained"
			sx={{ background: "#3B3B3B", width: 141, height: 42, borderRadius: "0.5rem" }}
			onClick={(e) => exportToCSV(csvData, fileName)}
		>
			엑셀 다운로드
		</Button>
	);
};

export default ExportCSV;
