import React, { useState, useEffect, useContext } from "react";
import "./style.css";
import Stack from "@mui/material/Stack";
import FilterInputWrapper from "../NewLayout/FilterInputWrapperV2";
import { Button, Grid } from "@mui/material";
import {TextareaAutosize} from "@mui/base";
import SelectInput from "src/components/CustomComponent/SelectInput";
import userAPi from "src/api/MemberApi";
import { PenatyAPi } from "src/api/index";
import "./styles.scss";
import { DateInput } from "../Fields";
import { convertTextToKorean, convertTimeZone } from "src/util/convert";
import { getAgeUser, converAgeToMilisecond } from "src/util/Utils";
import API from "src/api/MemberApi";
import { showError, showSuccess } from "src/util/error";
import { LoadingContext } from "src/providers/LoadingProvider";
import moment from "moment";
import { getAppName, getAppVersion } from "src/util/data";

const ageData = [];
for (let i = 1; i <= 100; i++) {
	ageData.push({ title: i.toString(), value: i });
}

const statusAccount = [
	{ title: "정상", value: "NORMAL" },
	{ title: "계정 정지", value: "BLOCK" },
];
const methodBlock = [{ title: "관리자블럭", value: "ADMINBLOCK" }];
const reasonBlock = [
	{ title: "광고 회원", value: "ADV" },
	{ title: "불량회원", value: "USER_VIOLET" },
	{ title: "불량대화", value: "CONTENT_VIOLET" },
	{ title: "불량사진", value: "IMG_VIOLET" },
];
const gender = [
	{ title: "남자", value: "MALE" },
	{ title: "여자", value: "FEMALE" },
];
// const reasonDelete = [
// 	{ title: "광고회원", value: "ADV" },
// 	{ title: "불량회원", value: "USER_VIOLET_STANDARD" },
// 	{ title: "관리자탈퇴", value: "ADMIN_DELETE" },
// ];
// const methodDelete = [
// 	{ title: "본인탈퇴", value: "USER_DELETE" },
// 	{ title: "관리자 탈퇴", value: "ADMIN_DELETE" },
// ];

const UserInfo = ({ hastag, featchHashTag, data, fetchAll, setReloadApiAfterChange }) => {
	const [platform, setPlatform] = useState();
	const [ip, setIp] = useState({ register_ip: null, last_login_ip: null });
	const { showLoading, hideLoading } = useContext(LoadingContext);
	const [adminNote, setAdminNote] = useState("");
	const [basicInfo, setBasicInfo] = useState({
		nickname: "",
		birthday: data?.birthday ? Number(getAgeUser(data?.birthday)) : -1,
		pluskit_video_view_time: 0,
		sex: ""
	});
	const [status_account, setStatus_account] = useState({
		status_account: "",
		methodBlock: "",
		reasonBlock: "",
	});
	const [dateBlock, setDateBlock] = useState(0);
	const [appInfo, setAppInfo] = useState({
		app_version: "",
		app_name: "",
	});

	const handleGetDataUser = async () => {
		try {
			const res = await userAPi.userProfileASP(data?.id);
			setPlatform(res?.results?.object?.asp_user?.asp_name);
			setIp({
				register_ip: res?.results?.object?.asp_user?.register_ip || "-",
				last_login_ip: res?.results?.object?.asp_user?.last_login_ip || "-",
			});
		} catch (error) {}
	};

	useEffect(() => {
		setAdminNote(data?.adminNote);
		setStatus_account({
			status_account: data?.status_account,
			methodBlock: data?.penalty?.type_block,
			reasonBlock: data?.penalty?.reason,
			reasonDelete: data?.penalty?.reason_delete,
			methodDelete: data?.penalty?.type_delete,
		});
		+data?.penalty?.Block_at_unix_timestamp &&
			setDateBlock(new Date(+data?.penalty?.Block_at_unix_timestamp));

		setBasicInfo({
			nickname: data?.nickname,
			birthday: data?.birthday ? getAgeUser(data?.birthday) : "-1",
			pluskit_video_view_time:
				data?.pluskit_video_view_time > 0 ? Math.floor(data.pluskit_video_view_time / 20) : 0,
			sex: data?.sex
		});

		handleGetDataUser();

		setAppInfo({
			app_name: getAppName(data),
			app_version: getAppVersion(data),
		});
	}, [data]);

	const handleChange = (e, key, action, state) => {
		action({ ...state, [key]: e.target.value });
	};

	// duplicate name, please change name if you see this line of code, now i can not  fix because i am so busy
	const HandleUpdate = async (body) => {
		showLoading();
		try {
			const res = await API.update(body, data.id);
			if (res.code === 200) {
				fetchAll();
				featchHashTag();
				showSuccess();
			}
			hideLoading();
		} catch (error) {
			hideLoading();
			showError(error.response.data.message);
		}
	};

	const handleBlockAndDeleteAccoint = async (body) => {
		showLoading();
		try {
			await API.update({ status_account: body.status_account }, data.id);

			if (data.penalty) {
				// do some thing here
				const res2 = await PenatyAPi.update(
					{
						reason: body.reasonBlock,

						type_block: body.methodBlock,
						Block_at_unix_timestamp: dateBlock ? moment(dateBlock).valueOf() : 0,
					},
					data.penalty.id
				);

				if (res2.code === 200) {
					showSuccess();

					fetchAll();
				}
			} else {
				const res2 = await PenatyAPi.add({
					user_id: data?.id,
					reason: body.reasonBlock,
					type_block: body.methodBlock,
					Block_at_unix_timestamp: dateBlock ? moment(dateBlock).valueOf() : 0,
				});

				if (res2.code === 200) {
					fetchAll();
				}

				// do some thing here
			}
			hideLoading();
		} catch (error) {
			hideLoading();
			showError(error.response.data.message);
		}
	};

	const handlePreUpdate = () => {
		const body = {
			...basicInfo,
			birthday: converAgeToMilisecond(basicInfo.birthday),
			pluskit_video_view_time: basicInfo.pluskit_video_view_time * 20,
		};
		return body;
	};
	const handleUpdateUserSetting = async () => {
		showLoading();
		if (basicInfo.pluskit_video_view_time > 3 || basicInfo.pluskit_video_view_time < 0) {
			showError("무료보기의 숫자는 0부터 3까지만");
			hideLoading();
			return;
		}

		try {
			const body = handlePreUpdate();
			const res1 = await userAPi.update({ ...body }, data.id);
			if (res1.code === 200) {
				fetchAll();
				featchHashTag();
				setReloadApiAfterChange(true);
				showSuccess();
			}
			hideLoading();
		} catch (error) {
			showError(error.response.data.message);
			hideLoading();
		}
	};
	useEffect(() => {
		if (status_account?.status_account === "NORMAL") {
			setStatus_account((pre) => ({
				...pre,
				methodBlock: "",
				reasonBlock: "",
			}));
			setDateBlock(null);
		}
	}, [JSON.stringify(status_account.status_account)]);
	return (
		<>
			<Stack spacing={2}>
				{/* avatar */}

				{/*basic info */}
				<div>
					<div
						style={{
							background: "#F6F6F6 0% 0% no-repeat padding-box",
							border: "1px solid #E6ECF3",
							height: "50px",
							padding: "13px 22px",
						}}
					>
						<p>기본정보</p>
					</div>

					<Grid
						container
						rowSpacing={1}
						sx={{ mt: 1, p: 2 }}
						columnSpacing={{ xs: 1, sm: 2, md: 3 }}
					>
						<Grid item xs={4}>
							{/* id */}
							<FilterInputWrapper label={/*sCeleb(data) ? "셀럽 ID" :*/ "회원 ID"}>
								<input
									type="text"
									value={data?.identified_id}
									disabled
									className="custom-input-disabled"
								/>
							</FilterInputWrapper>
							{/* gender */}
							<FilterInputWrapper label={"성별"}>
								<SelectInput
									data={gender}
									className="select"
									value={basicInfo?.sex}
									handleChange={(e) => {
										handleChange(e, "sex", setBasicInfo, basicInfo);
									}}
								/>
							</FilterInputWrapper>
							{/* desc*/}
						</Grid>
						<Grid item xs={4}>
							{/* nickname */}
							<FilterInputWrapper label={"닉네임"}>
								<input
									type="text"
									value={basicInfo.nickname}
									onChange={(e) => handleChange(e, "nickname", setBasicInfo, basicInfo)}
									className="custom-input"
								/>
							</FilterInputWrapper>
							{/* platform */}
							<FilterInputWrapper label={"이메일"}>
								<input type="text" value={platform} className="custom-input-disabled" disabled />
							</FilterInputWrapper>
						</Grid>
						<Grid item xs={4}>
							{/* birthday */}
							<FilterInputWrapper label={"나이"}>
								<SelectInput
									data={ageData}
									className="select"
									value={basicInfo.birthday}
									handleChange={(e) => {
										handleChange(e, "birthday", setBasicInfo, basicInfo);
									}}
								/>
							</FilterInputWrapper>
							{/* status */}
							<FilterInputWrapper label={"상태"}>
								<input
									type="text"
									className="custom-input-disabled"
									disabled
									value={convertTextToKorean(data?.status_account)}
								/>
							</FilterInputWrapper>
							{/* level */}
						</Grid>
					</Grid>
				</div>

				{/*pluskit video view time */}
				<div>
					<div
						style={{
							background: "#F6F6F6 0% 0% no-repeat padding-box",
							border: "1px solid #E6ECF3",
							height: "50px",
							padding: "13px 22px",
						}}
					>
						<p>무료보기</p>
					</div>

					<Grid
						container
						rowSpacing={1}
						sx={{ mt: 1, p: 2 }}
						columnSpacing={{ xs: 1, sm: 2, md: 3 }}
					>
						<Grid item xs={4}>
							{/* id */}
							<FilterInputWrapper label={"무료 보기"}>
								<input
									type="text"
									value={basicInfo?.pluskit_video_view_time}
									onChange={(e) => {
										e.target.value >= 0 &&
											e.target.value <= 3 &&
											handleChange(e, "pluskit_video_view_time", setBasicInfo, basicInfo);
									}}
									className="custom-input"
								/>
							</FilterInputWrapper>
						</Grid>
					</Grid>
				</div>

				{/*Registration / login information*/}
				<div>
					<div
						style={{
							background: "#F6F6F6 0% 0% no-repeat padding-box",
							border: "1px solid #E6ECF3",
							height: "50px",
							padding: "13px 22px",
						}}
					>
						<p>가입&로그인정보</p>
					</div>

					<Grid
						container
						rowSpacing={1}
						sx={{ mt: 1, p: 2 }}
						columnSpacing={{ xs: 1, sm: 2, md: 3 }}
					>
						<Grid item xs={4}>
							{/* sigun-up day */}
							<FilterInputWrapper label={"가입 일자"}>
								<input
									type="text"
									value={convertTimeZone(+data?.created_at_unix_timestamp)}
									readOnly
									className="custom-input-disabled"
									disabled
								/>
							</FilterInputWrapper>
							{/* sigun-in day */}
							<FilterInputWrapper label={"로그인 일자"}>
								<input
									type="text"
									value={convertTimeZone(+data?.last_open_app_unix_timestamp)}
									readOnly
									className="custom-input-disabled"
									disabled
								/>
							</FilterInputWrapper>

							{/* User ID */}
							<FilterInputWrapper label={"User ID"}>
								<input
									type="text"
									value={data?.identified_id}
									readOnly
									className="custom-input-disabled"
									disabled
								/>
							</FilterInputWrapper>
						</Grid>
						<Grid item xs={4}>
							{/* sigun-in device  */}
							<FilterInputWrapper label={"가입IP "}>
								<input
									type="text"
									value={ip?.register_ip}
									className="custom-input-disabled"
									disabled
								/>
							</FilterInputWrapper>
							{/* sigun-in platfomr  */}
							<FilterInputWrapper label={"로그인IP"}>
								<input
									type="text"
									value={ip?.last_login_ip}
									className="custom-input-disabled"
									disabled
								/>
							</FilterInputWrapper>

							{/* E-mail */}
							{data?.email && (
								<FilterInputWrapper label={"E-mail"}>
									<input
										type="text"
										value={data?.email}
										readOnly
										className="custom-input-disabled"
										disabled
									/>
								</FilterInputWrapper>
							)}
						</Grid>
						<Grid item xs={4}>
							{/* OS app download  */}
							<FilterInputWrapper label={"설치한 앱 명"}>
								<input
									type="text"
									value={appInfo.app_name}
									readOnly
									className="custom-input-disabled"
									disabled
								/>
							</FilterInputWrapper>
							{/* app sersion */}
							<FilterInputWrapper label={"설치한 앱 버전"}>
								<input
									type="text"
									value={appInfo.app_version}
									readOnly
									className="custom-input-disabled"
									disabled
								/>
							</FilterInputWrapper>
							{/* app platform */}
							<FilterInputWrapper label={"설치한 플랫폼"}>
								<input
									type="text"
									value={data?.app_platform}
									readOnly
									className="custom-input-disabled"
									disabled
								/>
							</FilterInputWrapper>
						</Grid>
					</Grid>
				</div>
				{/* button */}
				<div style={{ display: "flex", justifyContent: "center" }}>
					<Button
						variant="contained"
						sx={{
							background: "#3B3B3B ",
							border: "1px solid #E1E1E1",
							borderRadius: "10px",
							width: "300px",
							height: "34px",
						}}
						onClick={handleUpdateUserSetting}
					>
						회원 정보 변경
					</Button>
				</div>
				{/* block and delete account */}
				<div>
					<div
						style={{
							background: "#F6F6F6 0% 0% no-repeat padding-box",
							border: "1px solid #E6ECF3",
							height: "50px",
							padding: "13px 22px",
						}}
					>
						<p>계정정지</p>
					</div>

					<Grid
						container
						rowSpacing={1}
						sx={{ mt: 1, p: 2 }}
						columnSpacing={{ xs: 1, sm: 2, md: 3 }}
					>
						<Grid item xs={4}>
							{/* status account */}
							<FilterInputWrapper label={"상태"}>
								<SelectInput
									data={statusAccount}
									className="select"
									value={status_account?.status_account}
									handleChange={(e) => {
										handleChange(e, "status_account", setStatus_account, status_account);
									}}
								/>
							</FilterInputWrapper>
							{/* METHOD BLOCK */}
							<FilterInputWrapper label={"계정정지방식"}>
								<SelectInput
									data={methodBlock}
									className="select"
									value={status_account.methodBlock}
									handleChange={(e) => {
										handleChange(e, "methodBlock", setStatus_account, status_account);
									}}
								/>
							</FilterInputWrapper>
							{/* METHOD DELETE */}
							{/* <FilterInputWrapper label={"탈퇴방식"}>
								<SelectInput
									data={methodDelete}
									className="select"
									value={status_account.methodDelete}
									handleChange={(e) => {
										handleChange(e, "methodDelete", setStatus_account, status_account);
									}}
								/>
							</FilterInputWrapper> */}
						</Grid>

						<Grid item xs={4}>
							{/* REASON BLOCK */}
							<FilterInputWrapper label={"계정정지사유"}>
								<SelectInput
									data={reasonBlock}
									className="select"
									value={status_account.reasonBlock}
									handleChange={(e) => {
										handleChange(e, "reasonBlock", setStatus_account, status_account);
									}}
								/>
							</FilterInputWrapper>
							{/* REASON DELETE */}
							{/* <FilterInputWrapper label={"탈퇴사유"}>
								<SelectInput
									data={reasonDelete}
									className="select"
									value={status_account.reasonDelete}
									handleChange={(e) => {
										handleChange(e, "reasonDelete", setStatus_account, status_account);
									}}
								/>
							</FilterInputWrapper> */}
						</Grid>
						<Grid item xs={4}>
							{/* DATE BLOCK */}
							<FilterInputWrapper label={"계정정지일시"}>
								<DateInput
									date={dateBlock}
									setDate={setDateBlock}
									placeholder={"YYYY/MM/DD HH:mm"}
									dateFormat={"yyyy/MM/dd HH:mm"}
									isShowTime={true}
								/>
							</FilterInputWrapper>
							{/* DATE DELETE */}
						</Grid>
					</Grid>
					<div style={{ display: "flex", justifyContent: "center" }}>
						<Button
							variant="contained"
							sx={{
								background: "#3B3B3B ",
								border: "1px solid #E1E1E1",
								borderRadius: "10px",
								width: "300px",
								height: "34px",
							}}
							onClick={() => {
								handleBlockAndDeleteAccoint(status_account);
							}}
						>
							계정 상태 변경
						</Button>
					</div>
				</div>

				{/* admin not*/}
				<div>
					<div
						style={{
							background: "#F6F6F6 0% 0% no-repeat padding-box",
							border: "1px solid #E6ECF3",
							height: "50px",
							padding: "13px 22px",
						}}
					>
						<p>관리자 메모</p>
					</div>

					<Grid
						container
						rowSpacing={1}
						sx={{ mt: 1, p: 1 }}
						columnSpacing={{ xs: 1, sm: 2, md: 3 }}
					>
						<Grid item xs={12} sx={{ display: "flex" }}>
							<TextareaAutosize
								style={{ width: "calc(100% - 146px)", height: 200, border: "1px solid black" }}
								value={adminNote}
								onChange={(e) => setAdminNote(e.target.value)}
							/>
							<div style={{ display: "flex", alignItems: "flex-end" }}>
								<Button
									onClick={() => {
										HandleUpdate({
											adminNote: adminNote,
										});
									}}
									variant="contained"
									sx={{
										background: "#3B3B3B ",
										border: "1px solid #E1E1E1",
										borderRadius: "10px",
										width: "136px",
										height: "54px",
										marginLeft: "10px",
									}}
								>
									저장
								</Button>
							</div>
						</Grid>
					</Grid>
				</div>
			</Stack>
		</>
	);
};

export default UserInfo;
