// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-info input {
	width: calc(100% - 136px);
	height: 100%;
	border: none;
	padding-left: 10px;
}
.user-info label {
	background: #f6f6f6 0% 0% no-repeat padding-box;
	height: 100%;
	width: 136px !important;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	margin: 0;
}

.user-info {
	display: flex;
	align-items: center;
	height: 54px;
	width: 20%;
	border: 1px solid #e6ecf3;
}

.user-avatar-container {
	display: flex;
}
.select{
	margin-left: 0px;	
	width: 214.400px;
	  
	  
}`, "",{"version":3,"sources":["webpack://./src/components/phase2/userProfileDetail/style.css"],"names":[],"mappings":"AAAA;CACC,yBAAyB;CACzB,YAAY;CACZ,YAAY;CACZ,kBAAkB;AACnB;AACA;CACC,+CAA+C;CAC/C,YAAY;CACZ,uBAAuB;CACvB,aAAa;CACb,mBAAmB;CACnB,uBAAuB;CACvB,kBAAkB;CAClB,SAAS;AACV;;AAEA;CACC,aAAa;CACb,mBAAmB;CACnB,YAAY;CACZ,UAAU;CACV,yBAAyB;AAC1B;;AAEA;CACC,aAAa;AACd;AACA;CACC,gBAAgB;CAChB,gBAAgB;;;AAGjB","sourcesContent":[".user-info input {\n\twidth: calc(100% - 136px);\n\theight: 100%;\n\tborder: none;\n\tpadding-left: 10px;\n}\n.user-info label {\n\tbackground: #f6f6f6 0% 0% no-repeat padding-box;\n\theight: 100%;\n\twidth: 136px !important;\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\ttext-align: center;\n\tmargin: 0;\n}\n\n.user-info {\n\tdisplay: flex;\n\talign-items: center;\n\theight: 54px;\n\twidth: 20%;\n\tborder: 1px solid #e6ecf3;\n}\n\n.user-avatar-container {\n\tdisplay: flex;\n}\n.select{\n\tmargin-left: 0px;\t\n\twidth: 214.400px;\n\t  \n\t  \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
