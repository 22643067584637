import { createContext, useState } from "react";
import UserProfile from "src/components/phase2/UserProfile";

const UserProfileContext = createContext();

const UserProvider = ({ children }) => {
	const [reLoadApiAfterChange, setReloadApiAfterChange] = useState(false);
	const [open, setOpen] = useState(false);
	const [id, setid] = useState("");
	const [isReloadApi, setIsReloadApi] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => {
		setOpen(false);
		setid(null);
	};
	const handleReloadApiAfterChange = () => {
		setReloadApiAfterChange(false);
	};
	const handleReloadApi = () => {
		setIsReloadApi(!isReloadApi);
	};

	const value = {
		handleClose,
		handleOpen,
		setid,
		isReloadApi,
		handleReloadApi,
		handleReloadApiAfterChange,
		reLoadApiAfterChange,
	};

	return (
		<UserProfileContext.Provider value={value}>
			{children}
			{open && (
				<UserProfile
					open={open}
					handleClose={handleClose}
					id={id}
					setReloadApiAfterChange={setReloadApiAfterChange}
				/>
			)}
		</UserProfileContext.Provider>
	);
};

export { UserProvider, UserProfileContext };
