import React, { useRef, useState } from "react";
import "./style.css";

const VideoPlayer = ({ link }) => {
	const videoRef = useRef(null);
	const [isPlaying, setIsPlaying] = useState(false);

	const handlePlayVideo = () => {
		videoRef && videoRef.current && videoRef.current.play();
		setIsPlaying(true);
	};

	const handlePauseVideo = () => {
		videoRef && videoRef.current && videoRef.current.pause();
		setIsPlaying(false);
	};

	return (
		<div className="video" onClick={isPlaying ? handlePauseVideo : handlePlayVideo}>
			<video ref={videoRef} width={"100%"} height={"100%"} controls>
				<source src={link} type="video/mp4" />
			</video>
		</div>
	);
};

export default VideoPlayer;
