import axiosClient from "./axiosClient";
const token = JSON.parse(localStorage.getItem("token"));
const HOSTNAME = process.env.REACT_APP_API;
const fields = 'fields=["$all"]';
const table = "hashtag";

const Api = {
	/**
	 *
	 *
	 * FILTER CRUD
	 *
	 */
	userHashTagUpdate: (userID, hashTagID) => {
		const body = {
			hashtag_ids: hashTagID,
			user_id: userID,
		};
		const url = `${HOSTNAME}/user_hashtag/admin_update_multi_hashtag`;
		return axiosClient.put(url, body, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
	},

	filter: (text, page, limit, column, state) => {
		let url = "";
		let filedFullname = "";
		if (state && column) {
			url = `${HOSTNAME}/${table}?${
				filedFullname ? filedFullname : fields
			}&page=${page}&limit=${limit}&filter={"${column}":{"$iLike" : "%25${text}%25"},"type":"${state}"}&order=[["created_at","desc"]]`;
		} else if (state) {
			url = `${HOSTNAME}/${table}?${
				filedFullname ? filedFullname : fields
			}&page=${page}&limit=${limit}&filter={"type":"${state}"}&order=[["created_at","desc"]]`;
		} else
			url = `${HOSTNAME}/${table}?${
				filedFullname ? filedFullname : fields
			}&page=${page}&limit=${limit}&filter={"${column}":{"$iLike" : "%25${text}%25"}}&order=[["created_at","desc"]]`;

		return axiosClient.get(url);
	},

	findAll: (page, limit) => {
		const url = `${HOSTNAME}/${table}/admin/get_list_cms?${fields}&page=${page}&limit=${limit}&order=[["created_at", "desc"]]`;
		return axiosClient.get(url);
	},
	findOne: (id) => {
		const url = `${HOSTNAME}/${table}/${id}?${fields}`;
		return axiosClient.get(url, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
	},

	add: (body) => {
		const url = `${HOSTNAME}/${table}`;
		return axiosClient.post(url, body, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
	},

	update: (body, id) => {
		const url = `${HOSTNAME}/${table}/${id}`;
		return axiosClient.put(url, body, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
	},

	delete: (id) => {
		const url = `${HOSTNAME}/${table}/${id}`;
		return axiosClient.delete(url, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
	},
	getAll(page = 1, limit = 20, filter, order) {
		const url = `${HOSTNAME}/${table}/admin/get_list_cms?${fields}&filter=${filter}&page=${page}&limit=${limit}&order=[${
			order || '["created_at","desc"]'
		}]`;
		return axiosClient.get(url);
	},
	updateMulti(body, filter) {
		const url = `${HOSTNAME}/${table}/admin/update_multi?filter={"id" : ${JSON.stringify(filter)}}`;
		return axiosClient.put(url, body, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
	},
	updateHashtagLounge(bodyRes2) {
		const url = `${HOSTNAME}/user_hashtag/admin_update_hashtag_lounge`;
		return axiosClient.post(
			url,
			{ ...bodyRes2 },
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
	},
};

export default Api;
