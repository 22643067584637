import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import CheckBox from "@mui/material/Checkbox";
const CheckBoxField = ({ field, form, data, action }) => {
	return (
		<>
			{data.map((item, index) => (
				<FormControlLabel
					key={index}
					control={
						<CheckBox
							checked={item.checked}
							onChange={(e) => action(e, field, form)}
							name={item.name}
							color="default"
						/>
					}
					label={item.label}
				/>
			))}
		</>
	);
};

export default CheckBoxField;
