import { useContext } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from 'react-router-dom';
import logo from "src/assets/img/new_logo.png";
import NavItem, {
	navigationFansomeASP,
	navigationAdminASP,
	navigationContentManager,
	navigationSuperAdminASP,
} from "./Nav";
import "./style.css";
import { UserProfileContext } from "src/providers/UserProvider";
import { getAdminType } from "src/util/Utils";
import { adminType } from "src/util/data";
const drawerWidth = 385;

const SingleNavItem = ({ item }) => {
	const [expanded, setExpanded] = React.useState(false);
	const { isReloadApi, handleReloadApi } = useContext(UserProfileContext);

	const { t } = useTranslation();
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		if (location.pathname) {
			setExpanded(
				item?.childNavigation?.filter((el) => el?.to === location.pathname.toString())
					?.length > 0
					? true
					: false
			);
		}
	}, [location.pathname]);

	return (
		<>
			{item.childNavigation ? (
				<Accordion
					style={{
						width: "100%",
						borderRadius: 0,
						boxShadow: "none",
						border: "1px solid #e2e2e2",
					}}
					expanded={expanded}
					onChange={(e, expanded) => setExpanded(expanded)}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<p style={{ paddingLeft: "23px", marginBottom: 0 }}>{t(item.title)}</p>
					</AccordionSummary>
					<AccordionDetails style={{ padding: 0, width: "100%" }}>
						{item.childNavigation.map((item, index) => (
							<ListItem key={index} style={{ width: "100%", padding: 0, background: "#F6F6F6" }}>
								<ListItemButton
									style={{ paddingLeft: "50px" }}
									onClick={() => {
										if (item.to === location.pathname) {
											handleReloadApi(!isReloadApi);
										} else navigate(`${item.to}`);
									}}
								>
									<p
										style={{
											color: `${item.to === location.pathname ? "black" : "#303030"} `,
											fontWeight: `${item.to === location.pathname ? 600 : ""}`,
										}}
									>
										{t(item.title)}
									</p>
								</ListItemButton>
							</ListItem>
						))}
					</AccordionDetails>
				</Accordion>
			) : (
				<ListItemButton
					style={{
						paddingLeft: "41px",
						background: `${item.to === location.pathname ? "#F6F6F6" : ""} `,
					}}
					onClick={() => {
						navigate(item.to);
						window.location.reload();
					}}
				>
					<p>{t(item.title)}</p>
				</ListItemButton>
			)}
		</>
	);
};

const TheSidebarDefault = () => {
	const [open] = React.useState(true);
	const employee = JSON.parse(localStorage.getItem("user"));
	const typeAdmin = getAdminType(employee?.type, employee?.username);

	const rendevNavItem = () => {
		return (
			<>
				{typeAdmin === adminType.SUPER_ADMIN
					? NavItem.map((item, index) => (
							<ListItem key={index} disablePadding>
								<SingleNavItem item={item} />
							</ListItem>
					  ))
					: typeAdmin === adminType.SUPER_ADMIN_ASP
					? navigationSuperAdminASP.map((item, index) => (
							<ListItem key={index} disablePadding>
								<SingleNavItem item={item} />
							</ListItem>
					  ))
					: typeAdmin === adminType.OPERATOR
					? navigationContentManager.map((item, index) => (
							<ListItem key={index} disablePadding>
								<SingleNavItem item={item} />
							</ListItem>
					  ))
					: typeAdmin === adminType.ADMIN_FANSOME_ASP
					? navigationFansomeASP.map((item, index) => (
							<ListItem key={index} disablePadding>
								<SingleNavItem item={item} />
							</ListItem>
					  ))
					: navigationAdminASP.map((item, index) => (
							<ListItem key={index} disablePadding>
								<SingleNavItem item={item} />
							</ListItem>
					  ))}
			</>
		);
	};
	return (
		<>
			<Drawer
				sx={{
					width: drawerWidth,
					flexShrink: 0,
					"& .MuiDrawer-paper": {
						boxSizing: "border-box",
						md: {
							width: 256,
						},
						lg: {
							width: 256,
						},
						xl: {
							width: drawerWidth,
						},
					},
				}}
				className="drawerCustom"
				variant="persistent"
				anchor="left"
				open={open}
			>
				<div className="sidebar--titleContainer">
					<img src={logo} alt={logo} width={60} height={60} />
					<div className="sidebar--title">
						<h1>Fansome</h1>
						<p>Admin System</p>
					</div>
				</div>
				<Divider />
				<List sx={{ paddingTop: 0 }}>{rendevNavItem()}</List>
			</Drawer>
		</>
	);
};

export default TheSidebarDefault;
