import {TextareaAutosize} from "@mui/base";
import { Button, Grid } from "@mui/material";
import Stack from "@mui/material/Stack";
import { useContext, useEffect, useRef, useState } from "react";
import userAPi from "src/api/MemberApi";
import UploadImg from "src/components/phase1/Field/uploadAvatar";
import { LoadingContext } from "src/providers/LoadingProvider";
import { IsCeleb, getAgeUser } from "src/util/Utils";
import { convertTimeZone, convertTextToKorean } from "src/util/convert";
import { showError, showSuccess } from "src/util/error";
import FilterInputWrapper from "../NewLayout/FilterInputWrapperV2";
import "./style.css";
import { getAppName, getAppVersion } from "src/util/data";

const UserInfo = ({ data, fetchAll, setReloadApiAfterChange }) => {
	const { showLoading, hideLoading } = useContext(LoadingContext);
	const [urlImg, setUrlImg] = useState(null);
	const inputRef = useRef(null);
	const [content, setContent] = useState(data?.admin_note);
	const [platform, setPlatform] = useState();
	const [ip, setIp] = useState({ register_ip: null, last_login_ip: null });
	const [appInfo, setAppInfo] = useState({
		app_version: "",
		app_name: "",
	});

	const handleGetDataUser = async () => {
		try {
			const res = await userAPi.userProfileASP(data?.id);
			setPlatform(res?.results?.object?.asp_user?.asp_name);
			setIp({
				register_ip: res?.results?.object?.asp_user?.register_ip || "-",
				last_login_ip: res?.results?.object?.asp_user?.last_login_ip || "-",
			});
		} catch (error) {}
	};
	useEffect(() => {
		setUrlImg(data?.avatar[0]);
		setContent(data?.admin_note || "");
		handleGetDataUser();

		setAppInfo({
			app_name: getAppName(data),
			app_version: getAppVersion(data),
		});
	}, [data]);
	const totalPayment = (arr) => {
		return arr?.reduce((total, item) => (total += item?.pack?.price), 0);
	};

	const handleAvatar = async (body) => {
		showLoading();
		try {
			const res = await userAPi.adminUpdate(body);
			if (res.code === 200) {
				showSuccess();
				fetchAll();
				setReloadApiAfterChange(true);
			}
		} catch (error) {
		}
		hideLoading();
	};

	const handleSubmit = async () => {
		showLoading();
		try {
			const res = await userAPi.update({ admin_note: content }, data.id);
			if (res.code === 200) {
				fetchAll();
				showSuccess();
			}
			hideLoading();
		} catch (error) {
			hideLoading();
			showError(error);
		}
	};

	return (
		<>
			<Stack spacing={2}>
				{/* avatar */}
				<div>
					<div
						style={{
							background: "#F6F6F6 0% 0% no-repeat padding-box",
							border: "1px solid #E6ECF3",
							height: "50px",
							padding: "13px 22px",
						}}
					>
						<p>사진정보</p>
					</div>

					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							padding: "20px",
						}}
					>
						<div
							className="user-avatar-container"
							style={{
								flex: 3,
							}}
						>
							<UploadImg
								thumbnail={urlImg}
								setUrlImg={setUrlImg}
								uploadRef={inputRef}
								URL={(img) =>
									handleAvatar({
										type: "ADD_AVATAR",
										photos: [img],
										user_id: data?.id,
									})
								}
							/>
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "space-around",
									marginLeft: "10px",
								}}
							>
								<Button
									variant="contained"
									sx={{
										background: "#FAFAFA ",
										border: "1px solid #E1E1E1",
										borderRadius: "10px",
										color: "black",
									}}
									onClick={() =>
										data?.avatar.length > 0 &&
										handleAvatar({
											type: "REMOVE_AVATAR",
											photos: [data?.avatar[0]],
											user_id: data?.id,
										})
									}
								>
									삭제
								</Button>
								<Button
									variant="contained"
									sx={{
										background: "#3B3B3B ",
										border: "1px solid #E1E1E1",
										borderRadius: "10px",
									}}
									onClick={() => inputRef && inputRef.current && inputRef.current.click()}
								>
									사진 등록
								</Button>
							</div>
						</div>
						{IsCeleb(data) && (
							<div style={{ flex: 1 }}>
								<FilterInputWrapper label={"총 적립금액"}>
									<input type="text" readOnly className="custom-input" />
								</FilterInputWrapper>
								<FilterInputWrapper label={"총 환급금액"}>
									<input type="text" readOnly className="custom-input" />
								</FilterInputWrapper>
								<FilterInputWrapper label={"현재 보유금액"}>
									<input type="text" readOnly className="custom-input" />
								</FilterInputWrapper>
							</div>
						)}
					</div>
				</div>

				{/*basic info */}
				<div>
					<div
						style={{
							background: "#F6F6F6 0% 0% no-repeat padding-box",
							border: "1px solid #E6ECF3",
							height: "50px",
							padding: "13px 22px",
						}}
					>
						<p>기본정보</p>
					</div>

					<Grid
						container
						rowSpacing={1}
						sx={{ mt: 1, p: 2 }}
						columnSpacing={{ xs: 1, sm: 2, md: 3 }}
					>
						<Grid item xs={4}>
							{/* id */}
							<FilterInputWrapper label={"회원 ID"}>
								<input
									type="text"
									value={data?.identified_id}
									readOnly
									className="custom-input"
									disabled
								/>
							</FilterInputWrapper>
							{/* gender */}
							<FilterInputWrapper label={"성별"}>
								<input
									type="text"
									value={convertTextToKorean(data?.sex)}
									readOnly
									className="custom-input"
									disabled
								/>
							</FilterInputWrapper>

							{/* payment */}
							<FilterInputWrapper label={"결제"}>
								<input
									type="text"
									value={`${totalPayment(data?.sales_histories)}/${
										data?.sales_histories?.length
									}회`}
									readOnly
									className="custom-input"
									disabled
								/>
							</FilterInputWrapper>
						</Grid>
						<Grid item xs={4}>
							{/* nickname */}
							<FilterInputWrapper label={"닉네임"}>
								<input
									type="text"
									value={data?.nickname}
									readOnly
									className="custom-input"
									disabled
								/>
							</FilterInputWrapper>
							{/* platform */}
							<FilterInputWrapper label={"플랫폼 "}>
								<input type="text" value={platform} readOnly className="custom-input" disabled />
							</FilterInputWrapper>

							{/* first payment time */}
							<FilterInputWrapper label={"최초 결제"}>
								<input
									type="text"
									value={convertTimeZone(+data?.sales_histories?.[0]?.created_at_unix_timestamp)}
									readOnly
									className="custom-input"
									disabled
								/>
							</FilterInputWrapper>
						</Grid>
						<Grid item xs={4}>
							{/* age */}
							<FilterInputWrapper label={"나이"}>
								<input
									type="text"
									value={getAgeUser(data?.birthday)}
									readOnly
									className="custom-input"
									disabled
								/>
							</FilterInputWrapper>
							{/* status */}
							<FilterInputWrapper label={"상태"}>
								<input
									type="text"
									readOnly
									className="custom-input"
									disabled
									value={convertTextToKorean(data?.status_account)}
								/>
							</FilterInputWrapper>

							{/* last payment time */}
							<FilterInputWrapper label={"최근 결제"}>
								<input
									type="text"
									value={convertTimeZone(
										+data?.sales_histories[data?.sales_histories?.length - 1]
											?.created_at_unix_timestamp
									)}
									readOnly
									className="custom-input"
									disabled
								/>
							</FilterInputWrapper>
						</Grid>
					</Grid>
				</div>

				{/*pluskit video view time */}
				<div>
					<div
						style={{
							background: "#F6F6F6 0% 0% no-repeat padding-box",
							border: "1px solid #E6ECF3",
							height: "50px",
							padding: "13px 22px",
						}}
					>
						<p>무료보기</p>
					</div>

					<Grid
						container
						rowSpacing={1}
						sx={{ mt: 1, p: 2 }}
						columnSpacing={{ xs: 1, sm: 2, md: 3 }}
					>
						<Grid item xs={4}>
							{/* id */}
							<FilterInputWrapper label={"무료 보기 "}>
								<input
									type="text"
									value={
										data?.pluskit_video_view_time > 0
											? Math.floor(data.pluskit_video_view_time / 20)
											: 0
									}
									className="custom-input"
									readOnly
									disabled
								/>
							</FilterInputWrapper>
						</Grid>
					</Grid>
				</div>

				{/*Registration / login information*/}
				<div>
					<div
						style={{
							background: "#F6F6F6 0% 0% no-repeat padding-box",
							border: "1px solid #E6ECF3",
							height: "50px",
							padding: "13px 22px",
						}}
					>
						<p>가입&로그인정보</p>
					</div>

					<Grid
						container
						rowSpacing={1}
						sx={{ mt: 1, p: 2 }}
						columnSpacing={{ xs: 1, sm: 2, md: 3 }}
					>
						<Grid item xs={4}>
							{/* sigun-up day */}
							<FilterInputWrapper label={"가입 일자"}>
								<input
									type="text"
									value={convertTimeZone(+data?.created_at_unix_timestamp)}
									readOnly
									className="custom-input"
									disabled
								/>
							</FilterInputWrapper>
							{/* sigun-in day */}
							<FilterInputWrapper label={"로그인 일자"}>
								<input
									type="text"
									value={convertTimeZone(+data?.last_open_app_unix_timestamp)}
									readOnly
									className="custom-input"
									disabled
								/>
							</FilterInputWrapper>

							{/* User ID */}
							<FilterInputWrapper label={"User ID"}>
								<input
									type="text"
									value={data?.identified_id}
									readOnly
									className="custom-input"
									disabled
								/>
							</FilterInputWrapper>
						</Grid>
						<Grid item xs={4}>
							{/* sigun-in device  */}
							<FilterInputWrapper label={"가입IP "}>
								<input
									type="text"
									value={ip?.register_ip}
									readOnly
									className="custom-input"
									disabled
								/>
							</FilterInputWrapper>
							{/* sigun-in platfomr  */}
							<FilterInputWrapper label={"로그인IP"}>
								<input
									type="text"
									value={ip?.last_login_ip}
									readOnly
									className="custom-input"
									disabled
								/>
							</FilterInputWrapper>

							{/* E-mail */}
							{data?.email && (
								<FilterInputWrapper label={"E-mail"}>
									<input
										type="text"
										value={data?.email}
										readOnly
										className="custom-input"
										disabled
									/>
								</FilterInputWrapper>
							)}
						</Grid>
						<Grid item xs={4}>
							{/* OS app download  */}
							<FilterInputWrapper label={"설치한 앱 명"}>
								<input
									type="text"
									value={appInfo.app_name}
									readOnly
									className="custom-input"
									disabled
								/>
							</FilterInputWrapper>
							{/* app sersion */}
							<FilterInputWrapper label={"설치한 앱 버전"}>
								<input
									type="text"
									value={appInfo.app_version}
									readOnly
									className="custom-input"
									disabled
								/>
							</FilterInputWrapper>
							{/* app platform */}
							<FilterInputWrapper label={"설치한 플랫폼"}>
								<input
									type="text"
									value={data?.app_platform}
									readOnly
									className="custom-input"
									disabled
								/>
							</FilterInputWrapper>
						</Grid>
					</Grid>
				</div>

				{/* admin not*/}
				<div>
					<div
						style={{
							background: "#F6F6F6 0% 0% no-repeat padding-box",
							border: "1px solid #E6ECF3",
							height: "50px",
							padding: "13px 22px",
						}}
					>
						<p>관리자 메모</p>
					</div>

					<Grid
						container
						rowSpacing={1}
						sx={{ mt: 1, p: 1 }}
						columnSpacing={{ xs: 1, sm: 2, md: 3 }}
					>
						<Grid item xs={12} sx={{ display: "flex" }}>
							<TextareaAutosize
								style={{ width: "calc(100% - 146px)", height: 200, border: "1px solid black" }}
								value={content}
								onChange={(e) => setContent(e.target.value)}
							/>
							<div style={{ display: "flex", alignItems: "flex-end" }}>
								<Button
									variant="contained"
									sx={{
										background: "#3B3B3B ",
										border: "1px solid #E1E1E1",
										borderRadius: "10px",
										width: "136px",
										height: "54px",
										marginLeft: "10px",
									}}
									onClick={handleSubmit}
								>
									저장
								</Button>
							</div>
						</Grid>
					</Grid>
				</div>
			</Stack>
		</>
	);
};

export default UserInfo;
