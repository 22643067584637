// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-muitiselect {
  z-index: 10000;
  display: flex;
  flex-direction: row;
  margin-bottom: 9px;
}
.container-muitiselect .label-muiselect {
  width: 93.012px;
  color: black;
  font-weight: 600;
  font-size: 11px;
}

.container {
  width: 230px;
  display: flex;
  margin: 0px;
  padding: 0px 0 0 8px;
  border-radius: 10px;
}
.container .css-2m9kme-MuiFormControl-root {
  background: #fafafa;
  width: 216px;
  border-radius: 10px;
  border: 0.125px solid;
  margin: 0px;
}
.container .css-2m9kme-MuiFormControl-root .MuiOutlinedInput-root {
  border-radius: 10px;
}
.container .css-2m9kme-MuiFormControl-root .MuiOutlinedInput-root .MuiSelect-outlined {
  padding: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/phase2/userProfileDetailASP/styles.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;AACF;AACE;EACE,eAAA;EACA,YAAA;EACA,gBAAA;EACA,eAAA;AACJ;;AAGA;EACE,YAAA;EACA,aAAA;EACA,WAAA;EACA,oBAAA;EACA,mBAAA;AAAF;AAGE;EACE,mBAAA;EACA,YAAA;EACA,mBAAA;EACA,qBAAA;EACA,WAAA;AADJ;AAGI;EACE,mBAAA;AADN;AAGM;EACE,aAAA;AADR","sourcesContent":[".container-muitiselect {\n  z-index: 10000;\n  display: flex;\n  flex-direction: row;\n  margin-bottom: 9px;\n\n  .label-muiselect {\n    width: 101.012px - 8px;\n    color: black;\n    font-weight: 600;\n    font-size: 11px;\n  }\n}\n\n.container {\n  width: 230px;\n  display: flex;\n  margin: 0px;\n  padding: 0px 0 0 8px;\n  border-radius: 10px;\n\n\n  .css-2m9kme-MuiFormControl-root {\n    background: #fafafa;\n    width: 216px;\n    border-radius: 10px;\n    border: 0.125px solid;\n    margin: 0px;\n\n    .MuiOutlinedInput-root {\n      border-radius: 10px;\n\n      .MuiSelect-outlined {\n        padding: 10px\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
