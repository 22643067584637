import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import "core-js";
import "./polyfill";
import React from "react";
import App from "./App";
import "rsuite/dist/rsuite.min.css";
import "./scss/style.scss";
import { Provider } from "react-redux";
import i18n from "./translation/i18n";
import { I18nextProvider } from "react-i18next";
import { createRoot } from "react-dom/client";
import store from "./store";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
	<Provider store={store}>
		<I18nextProvider i18n={i18n}>
			<App />
		</I18nextProvider>
	</Provider>
);
