import { ROUTE } from "src/util/ROUTER";
const navigation = [
	{
		name: "payment statistics",
		title: "Payment Statistics",
		childNavigation: [
			{
				name: "Daily Sale",
				to: "/dailySale",
				title: "sideBar.Daily Sale",
			},
			{
				name: "Monthly Sale",
				to: "/monthlySale",
				title: "sideBar.Monthly Sale",
			},
			{
				name: "Purchase History",
				to: "/purchaseHistory",
				title: "sideBar.Purchase History",
			},
			{
				name: "Subscriber History",
				to: "/subscriberHistory",
				title: "sideBar.Subscriber History",
			},
			{
				name: "Web Purchase History",
				to: "/web_purchase_history",
				title: "sideBar.Web Purchase History",
			},
			{
				name: "accumulating stars celeb",
				to: "/accumulated_star_celeb",
				title: "sideBar.accumulating stars celeb",
			},
			{
				name: "Celeb Connection Status",
				to: ROUTE.CELEB_CONNECTION_STATUS,
				title: "sideBar.Celeb Connection Status",
			},
			{
				name: "VideoCallErrorStatistics",
				to: ROUTE.VIDEO_CALL_ERROR_STATISTICS,
				title: "sideBar.Video Call Error Statistics",
			},
		],
	},
	{
		name: "ManageCeleb",
		title: "Manage Celeb",
		childNavigation: [
			{
				name: "Accept Celeb",
				to: "/Accept",
				title: "sideBar.Accept celeb",
			},
			{
				name: "Subscription Celeb",
				to: "/supcriptionCeleb",
				title: "sideBar.Subscription celeb",
			},
			{
				name: "Agency",
				to: "/agency",
				title: "sideBar.Agency",
			},
			{
				name: "Post Celeb",
				to: "/postceleb",
				title: "sideBar.Post Celeb",
			},
			{
				name: "Chat Celeb",
				to: "/conversationceleb",
				title: "sideBar.Chat Celeb",
			},
			{
				name: "Follow Celeb",
				to: "/followCeleb",
				title: "sideBar.Follow Celeb",
			},
			{
				name: "Default Message Celeb",
				to: "/default_mess",
				title: "sideBar.Default Message Celeb",
			},
			{
				name: "Setting Default Message Celeb",
				to: "/setting_default_mess",
				title: "sideBar.Setting Default Message Celeb",
			},
			{
				name: "Default Message Celeb II",
				to: "/default_mess_2",
				title: "sideBar.Default Message Celeb II",
			},
			{
				name: "Setting Default Message Celeb II",
				to: "/setting_default_mess_2",
				title: "sideBar.Setting Default Message Celeb II",
			},
			// {
			// 	name: "Automatic message management for ASP only",
			// 	to: "/auto_mess_asp",
			// 	title: "sideBar.autoMessageManagementForASP",
			// },
			// {
			// 	name: "Automatic message settings management for ASP only",
			// 	to: "/setting_auto_mess_asp",
			// 	title: "sideBar.autoMessageSettingForASP",
			// },
			{
				name: "Intermediary fee",
				to: "/intermediary_fee",
				title: "sideBar.Intermediary fee",
			},
			{
				name: "Activity Status Celeb Management",
				to: ROUTE.ACTIVITY_STATUS_CELEB_MANAGEMENT,
				title: "sideBar.Activity Status Celeb Management",
			},
			{
				name: "On Off Pluskit Management",
				to: ROUTE.ON_OFF_PLUS_KIT_MANAGEMENT,
				title: "sideBar.On Off Pluskit Management",
			},
		],
	},

	{
		name: "ManageUser",
		title: "Manage User",
		childNavigation: [
			{
				name: "List User",
				to: "/listUser",
				title: "sideBar.List User",
			},
			{
				name: "Approve Post",
				to: "/approve_post",
				title: "sideBar.Approve Post",
			},
			{
				name: "Block User",
				to: "/blockuser",
				title: "sideBar.Block User",
			},
			{
				name: "Report User",
				to: "/report",
				title: "sideBar.Report User",
			},
			{
				name: "Profile Image",
				to: "/accept_img_profile",
				title: "sideBar.Profile Image",
			},
			{
				name: "Delete Account",
				to: "/delete_account",
				title: "sideBar.Delete Account",
			},
		],
	},
	{
		name: "StarManagement",
		title: "Star Management",
		childNavigation: [
			{
				name: "Star Log",
				to: "/star_log",
				title: "sideBar.Star Log",
			},
			{
				name: "Gift log",
				to: "/gift_log",
				title: "sideBar.Gift log",
			},
		],
	},
	{
		name: "ChatManagement",
		title: "Chat Management",
		childNavigation: [
			{
				name: "Video Call Log",
				to: "/video_call_log",
				title: "sideBar.Video Call Log",
			},
			{
				name: "Chat log",
				to: "/chat_log",
				title: "sideBar.Chat log",
			},
		],
	},
	{
		name: "BoardManagement",
		title: "Board Management",
		childNavigation: [
			{
				name: "notice",
				to: "/notice",
				title: "sideBar.Notice",
			},
			{
				name: "FAQ",
				to: "/faq",
				title: "FAQ",
			},
			{
				name: "Terms of service",
				to: "/terms_of_service",
				title: "sideBar.Terms of service",
			},
			{
				name: "Privacy",
				to: "/privacy",
				title: "sideBar.Privacy",
			},
			{
				name: "QnA",
				to: "/qna",
				title: "QnA",
			},
			{
				name: "Instagram User",
				to: "/ins_user",
				title: "Instagram User",
			},
		],
	},

	{
		name: "PaymentManagement",
		title: "Payment Management",
		childNavigation: [
			{
				name: "Inapp payment",
				to: "/inapp_payment_withdraw",
				title: "sideBar.Inapp payment",
			},
			// {
			// 	name: "PG payment",
			// 	to: "/pg_payment_withdraw",
			// 	title: "sideBar.PG payment",
			// },
			{
				name: "Celeb withdraw",
				to: "/celeb_withdraw",
				title: "sideBar.Celeb withdraw",
			},
			{
				name: "Celeb Request withdraw",
				to: "/celeb_request_withdraw",
				title: "sideBar.Celeb Request withdraw",
			},
			{
				name: "Agency withdraw",
				to: "/agency_withdraw",
				title: "sideBar.Agency withdraw",
			},
			{
				name: "Agency withdraw",
				to: "/agency_request_withdraw",
				title: "sideBar.Agency Request withdraw",
			},
		],
	},
	{
		name: "FunctionManagement",
		title: "Function Management",
		childNavigation: [
			{
				name: "SMS push",
				to: "/sms_push",
				title: "sideBar.SMS Push",
			},
			{
				name: "push_noti",
				to: "/push_noti",
				title: "sideBar.Push Noti",
			},
			{
				name: "agency_push_noti",
				to: "/agency_push_noti",
				title: "sideBar.Agency Push Noti ASP Member",
			},
			{
				name: "SMS Log",
				to: "/sms_log",
				title: "sideBar.SMS Log",
			},
			{
				name: "Push Log",
				to: "/push_log",
				title: "sideBar.Push Log",
			},
			{
				name: "Gift Registration Management",
				to: ROUTE.GIFT_REGISTRATION_MANAGEMENT,
				title: "sideBar.Gift Registration Management",
			},
		],
	},
	{
		name: "Representative celebs",
		title: "Representative celebs",
		childNavigation: [
			{
				name: "Discovery",
				to: "/dicovery",
				title: "Discovery",
			},
			{
				name: "Search",
				to: "/search",
				title: "Search",
			},
			{
				name: "Popular",
				to: "/popular",
				title: "인기있는 셀러브리티",
			},
			{
				name: "represent",
				to: "/represent",
				title: "대표 셀러브리티",
			},
		],
	},
	{
		name: "Management",
		title: "sideBar.Management",
		childNavigation: [
			{ name: "Hashtag", to: "/hashtag", title: "sideBar.HashtagV2" },
			{ name: "Banner", to: "/banner", title: "sideBar.Banner" },
			{ name: "HashtagLounge", to: "/hashtagLounge", title: "sideBar.Hashtag" },
			{ name: ROUTE.REELS, to: ROUTE.REELS, title: "sideBar.reels" },
			{ name: ROUTE.BANNER_ASP, to: ROUTE.BANNER_ASP, title: "sideBar.BannerAsp" },
			{ name: ROUTE.HASHTAG_ASP, to: ROUTE.HASHTAG_ASP, title: "sideBar.hashtag_asp" },
			{ name: ROUTE.REELS_ASP, to: ROUTE.REELS_ASP, title: "sideBar.reels_asp" },
		],
	},
	// {
	// 	name: "ASP WEB RTC Management",
	// 	title: "sideBar.ASP WEB RTC Management",
	// 	childNavigation: [
	// 		{
	// 			name: "ASP WEB RTC TYPE 1",
	// 			to: ROUTE.INTRO_ASP,
	// 			title: "sideBar.ASP WEB RTC TYPE 1",
	// 		},
	// 	],
	// },
	{ name: "Admin Management", to: "/employee", title: "sideBar.Admin Management" },

	{ name: "Dashboard", to: "/", title: "sideBar.Dashboard" },
	// { name: "Banner", to: "/banner", title: "sideBar.Banner" },
	// { name: ROUTE.BANNER_ASP, to: ROUTE.BANNER_ASP, title: "sideBar.BannerAsp" },
	// { name: ROUTE.HASHTAG_ASP, to: ROUTE.HASHTAG_ASP, title: "sideBar.hashtag_asp" },
	// { name: ROUTE.REELS_ASP, to: ROUTE.REELS_ASP, title: "sideBar.reels_asp" },

	// { name: "User", to: "/member", title: "sideBar.User" },
	{ name: "Default Message", to: "/default_message", title: "sideBar.Admin message" },
	{ name: "Policy", to: "/policy", title: "Policy" },
	{ name: "Version", to: "/version", title: "Version" },
	// { name: "Category", to: "/category", title: "sideBar.Category" },
];

export const navigationSuperAdminASP = [
	{
		name: "payment statistics",
		title: "Payment Statistics",
		childNavigation: [
			{
				name: "Daily Sale",
				to: ROUTE.DAILY_SALE_ASP,
				title: "sideBar.Daily Sale ASP",
			},
			{
				name: "Monthly Sale",
				to: ROUTE.MONTHLY_SALES_ASP,
				title: "sideBar.Monthly Sale ASP",
			},
			{
				name: "TimeZoneAccessStatistics",
				to: ROUTE.TIME_ZONE_ACCESS_STATISTICS,
				title: "sideBar.Access Statistics By Time Zone",
			},
			{
				name: "DailyAccessStatistics",
				to: ROUTE.DAILY_ACCESS_STATISTICS,
				title: "sideBar.Daily Access Statistics",
			},
		],
	},
	{
		name: "ManageUser",
		title: "Manage User",
		childNavigation: [
			{
				name: ROUTE.List_User_ASP,
				to: ROUTE.List_User_ASP,
				title: "sideBar.List User ASP",
			},
			{
				name: ROUTE.Profile_Image_ASP,
				to: ROUTE.Profile_Image_ASP,
				title: "sideBar.Profile Image ASP",
			},
			{
				name: ROUTE.Report_User_ASP,
				to: ROUTE.Report_User_ASP,
				title: "sideBar.Report User ASP",
			},
			{
				name: ROUTE.ASP_ACCOUNT_TESTING_MANAGEMENT,
				to: ROUTE.ASP_ACCOUNT_TESTING_MANAGEMENT,
				title: "sideBar.Account Testing ASP",
			},
		],
	},
	{
		name: "BoardManagement",
		title: "Board Management",
		childNavigation: [
			{
				name: "QnA",
				to: ROUTE.QnA_ASP,
				title: "sideBar.QnA ASP",
			},
		],
	},
	{
		name: "LogManagement",
		title: "Log Management",
		childNavigation: [
			{
				name: "Star Log ASP",
				to: ROUTE.STAR_LOG_ASP,
				title: "sideBar.Star Log ASP",
			},
			{
				name: "Coin Log ASP",
				to: ROUTE.COIN_LOG_ASP,
				title: "sideBar.Coin Log ASP",
			},
			{
				name: "Video Chat Log ASP",
				to: ROUTE.VIDEO_CHAT_LOG_ASP,
				title: "sideBar.Video Chat Log ASP",
			},
			{
				name: "Text Chat Log ASP",
				to: ROUTE.CHAT_LOG_ASP,
				title: "sideBar.Chat Log ASP",
			},
			{
				name: "Pluskit Purchase Log ASP",
				to: ROUTE.PLUSKIT_PURCHASE_LOG_ASP,
				title: "sideBar.Pluskit Purchase Log ASP",
			},
		],
	},
	{
		name: "Management",
		title: "sideBar.Management",
		childNavigation: [
			{ name: ROUTE.BANNER_ASP, to: ROUTE.BANNER_ASP, title: "sideBar.BannerAsp" },
			{ name: ROUTE.HASHTAG_ASP, to: ROUTE.HASHTAG_ASP, title: "sideBar.hashtag_asp" },
			{ name: ROUTE.REELS_ASP, to: ROUTE.REELS_ASP, title: "sideBar.reels_asp" },
		],
	},
	{ name: "Default Message", to: "/default_message", title: "sideBar.Admin message" },
	{ name: "Policy", to: "/policy", title: "Policy" },
	{ name: "Version", to: "/version", title: "Version" },
];

export const navigationAdminASP = [
	{
		name: "payment statistics",
		title: "Payment Statistics",
		childNavigation: [
			{
				name: "Daily Sale",
				to: ROUTE.DAILY_SALE_ASP,
				title: "sideBar.Daily Sale ASP",
			},
			{
				name: "Monthly Sale",
				to: ROUTE.MONTHLY_SALES_ASP,
				title: "sideBar.Monthly Sale ASP",
			},
		],
	},
	{
		name: "BoardManagement",
		title: "Board Management",
		childNavigation: [
			{
				name: "QnA",
				to: ROUTE.QnA_ASP,
				title: "sideBar.QnA ASP",
			},
		],
	},
	{
		name: "LogManagement",
		title: "Log Management",
		childNavigation: [
			{
				name: "Star Log ASP",
				to: ROUTE.STAR_LOG_ASP,
				title: "sideBar.Star Log ASP",
			},
		],
	},
];

export const navigationContentManager = [
	{
		name: "ASP WEB RTC Management",
		title: "sideBar.ASP WEB RTC Management",
		childNavigation: [
			{
				name: "ASP WEB RTC TYPE 1",
				to: ROUTE.INTRO_ASP,
				title: "sideBar.ASP WEB RTC TYPE 1",
			},
		],
	},
	{
		name: "Pluskit Video Management",
		title: "sideBar.Pluskit Video Management",
		childNavigation: [
			{
				name: "Pluskit Video Management",
				to: ROUTE.PLUSKIT_VIDEO_MANAGEMENT,
				title: "sideBar.Pluskit Video Management",
			},
		],
	},
	{
		name: "ManageCeleb",
		title: "Manage Celeb",
		childNavigation: [
			{
				name: "ASP Pluskit Celeb Management",
				to: ROUTE.ASP_PLUSKIT_CELEB_MANAGEMENT,
				title: "sideBar.ASP Pluskit Celeb Management",
			},
		],
	},
];

export const navigationFansomeASP = [
	{
		name: "payment statistics",
		title: "Payment Statistics",
		childNavigation: [
			{
				name: "Daily Sale",
				to: "/dailySale",
				title: "sideBar.Daily Sale",
			},
			{
				name: "Monthly Sale",
				to: "/monthlySale",
				title: "sideBar.Monthly Sale",
			},
			{
				name: "Purchase History",
				to: "/purchaseHistory",
				title: "sideBar.Purchase History",
			},
			{
				name: "TimeZoneAccessStatistics",
				to: ROUTE.TIME_ZONE_ACCESS_STATISTICS,
				title: "sideBar.Access Statistics By Time Zone",
			},
			{
				name: "DailyAccessStatistics",
				to: ROUTE.DAILY_ACCESS_STATISTICS,
				title: "sideBar.Daily Access Statistics",
			},
			// TODO : Star Usage Statistics - 스타 사용 통계
			{
				name: "Star Log Fansome ASP",
				to: "/star_log",
				title: "sideBar.Star Log Fansome ASP",
			},
			{
				name: "accumulating stars celeb",
				to: "/accumulated_star_celeb",
				title: "sideBar.accumulating stars celeb",
			},
		],
	},
	{
		name: "ManageUser",
		title: "Manage User",
		childNavigation: [
			{
				name: "List User",
				to: "/listUser",
				title: "sideBar.List User",
			},
			{
				name: "Profile Image",
				to: "/accept_img_profile",
				title: "sideBar.Profile Image",
			},
			{
				name: "Block User",
				to: "/blockuser",
				title: "sideBar.Block User",
			},
			{
				name: "Report User",
				to: "/report",
				title: "sideBar.Report User",
			},
			{
				name: "Delete Account",
				to: "/delete_account",
				title: "sideBar.Delete Account",
			},
		],
	},
	{
		name: "ChatManagement",
		title: "Chat Management",
		childNavigation: [
			{
				name: "Video Call Log",
				to: "/video_call_log",
				title: "sideBar.Video Call Log",
			},
			{
				name: "Chat log",
				to: "/chat_log",
				title: "sideBar.Chat log",
			},
		],
	},
	{
		name: "BoardManagement",
		title: "Board Management",
		childNavigation: [
			{
				name: "notice",
				to: "/notice",
				title: "sideBar.Notice",
			},
			{
				name: "FAQ",
				to: "/faq",
				title: "FAQ",
			},
			{
				name: "QnA",
				to: "/qna",
				title: "QnA",
			},
			{
				name: "Terms of service",
				to: "/terms_of_service",
				title: "sideBar.Terms of service",
			},
			{
				name: "Privacy",
				to: "/privacy",
				title: "sideBar.Privacy",
			},
		],
	},
	// { name: "Default Message", to: "/default_message", title: "sideBar.Admin message" },
	// { name: "Policy", to: "/policy", title: "Policy" },
	// { name: "Version", to: "/version", title: "Version" },
];

export default navigation;
