import React, { useState, useContext, useEffect } from "react";
import "./style.css";
import Stack from "@mui/material/Stack";
import FilterInputWrapper from "../NewLayout/FilterInputWrapperV2";
import { LoadingContext } from "src/providers/LoadingProvider";
import API from "src/api/InquiryApi";
import { Button, Grid } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";

import useFilters from "src/hooks/useFilter";

import { showError, showSuccess } from "src/util/error";
import { convertTimeZone } from "src/util/convert";
import { IsCeleb } from "src/util/Utils";

const SelectInput = styled(Select)(({}) => ({
	borderRadius: "10px",
	background: "#FAFAFA",
	border: "1px solid #E1E1E1",
	width: "100%",
	height: "100%",
	"& legend": { display: "none" },
	"& fieldset": { top: 0 },
}));

const UserInfo = ({ data }) => {
	const [items, setItems] = useState([]);
	const { showLoading, hideLoading } = useContext(LoadingContext);
	const { LIMITED, currentPage, spages, filter } = useFilters();
	const [editanswer, seteditanswer] = useState(true);
	const [ansewerValue, setansewerValue] = useState("");
	const [currentAnswer, setcurrentAnswer] = useState(null);
	const fetchAll = async () => {
		showLoading();
		try {
			const response = await API.getAll(
				currentPage.value,
				LIMITED.value,
				JSON.stringify({ user_id: data.id })
			);

			if (response.code === 200) {
				// xu li pagination
				let pages = Math.max(Math.ceil(response.results.objects.count / LIMITED.value), 1);
				spages.set(pages);
				setItems(response.results.objects.rows);
				setcurrentAnswer(response.results.objects.rows[0]);
				setansewerValue(response.results.objects.rows[0].answer);
				seteditanswer(true);
			}
		} catch (error) {
		} finally {
			hideLoading();
		}
	};

	useEffect(() => {
		fetchAll();
	}, [currentPage.value, LIMITED.value, filter.value]);

	const handleEditAnswer = () => seteditanswer(false);
	const handleReply = async () => {
		showLoading();
		try {
			if (!editanswer) {
				const res = await API.AdminReply({ answer: ansewerValue }, currentAnswer.id);
				if (res.code === 200) {
					showSuccess();
					fetchAll();
					hideLoading();
				}
			}
		} catch (error) {
			hideLoading();
			showError(error);
		}
	};
	const handleDelete = async () => {
		try {
			if (items.length > 0 && currentAnswer.id) {
				const res = await API.delete(currentAnswer.id);
				if (res.code === 200) {
					showSuccess();
					fetchAll();
				}
			}
		} catch (error) {
			showError(error);
		}
	};

	return (
		<>
			<Stack spacing={2}>
				<div>
					<div
						style={{
							background: "#F6F6F6 0% 0% no-repeat padding-box",
							border: "1px solid #E6ECF3",
							height: "50px",
							padding: "13px 22px",
						}}
						s
					>
						<p>문의사항</p>
					</div>
				</div>
				<div>
					<Grid
						container
						rowSpacing={1}
						sx={{ mt: 1, p: 2 }}
						columnSpacing={{ xs: 1, sm: 2, md: 3 }}
					>
						<Grid item xs={12}>
							<FilterInputWrapper label={"제목"}>
								<SelectInput
									value={currentAnswer}
									onChange={(e) => {
										setcurrentAnswer(e.target.value);
										setansewerValue(e.target.value.answer);
									}}
									style={{ transform: "translateX(-15%)" }}
								>
									{items?.map((item, index) => (
										<MenuItem key={index} value={item}>
											{item?.question || item?.title}
										</MenuItem>
									))}
								</SelectInput>
							</FilterInputWrapper>
						</Grid>
						<Grid item xs={6}>
							{/* id */}
							<FilterInputWrapper label={`${data && IsCeleb(data) ? "셀럽 ID" : "회원 ID"}`}>
								<input
									type="text"
									value={currentAnswer?.user?.identified_id}
									readOnly
									className="custom-input"
									style={{ width: "70%" }}
								/>
							</FilterInputWrapper>
							{/* inquri type */}
							<FilterInputWrapper label={"문의종류"}>
								<input
									type="text"
									value={currentAnswer?.type}
									readOnly
									className="custom-input"
									style={{ width: "70%" }}
								/>
							</FilterInputWrapper>
							{/* day asked */}
							<FilterInputWrapper label={"문의날짜"}>
								<input
									type="text"
									value={convertTimeZone(+currentAnswer?.created_at_unix_timestamp)}
									readOnly
									className="custom-input"
									style={{ width: "70%" }}
								/>
							</FilterInputWrapper>
							<FilterInputWrapper label={"문의내용"}>
								<textarea
									readOnly
									value={currentAnswer?.content}
									name=""
									id=""
									cols="30"
									rows="40"
									style={{ width: "70%", height: "200px", border: "1px solid black" }}
								></textarea>
							</FilterInputWrapper>
						</Grid>
						<Grid item xs={6}>
							<FilterInputWrapper
								label={`${data && IsCeleb(data) ? "셀럽 닉네임" : "회원 닉네임"}`}
							>
								{/* nickname */}
								<input
									type="text"
									value={currentAnswer?.user.nickname}
									readOnly
									className="custom-input"
									style={{ width: "70%" }}
								/>
							</FilterInputWrapper>
							{/* day ansewr */}
							<FilterInputWrapper label={"답변날짜"}>
								<input
									type="text"
									value={convertTimeZone(+currentAnswer?.processed_at_unix_timestamp)}
									readOnly
									className="custom-input"
									style={{ width: "70%" }}
								/>
							</FilterInputWrapper>
							<FilterInputWrapper label={"답변내용"}>
								<textarea
									value={ansewerValue}
									onChange={(e) => setansewerValue(e.target.value)}
									readOnly={editanswer}
									name=""
									id=""
									cols="30"
									rows="10"
									style={{ width: "70%", height: "200px", border: "1px solid black" }}
								></textarea>
							</FilterInputWrapper>
						</Grid>
						<Grid item xs={6}>
							<Button
								variant="contained"
								sx={{
									background: "#FFDBDB ",
									border: "1px solid #FEA0A0",
									borderRadius: "10px",
									width: "136px",
									height: "54px",

									color: "black",
								}}
								onClick={handleDelete}
							>
								삭제하기
							</Button>
						</Grid>
						<Grid item xs={6} sx={{ display: "flex", justifyContent: "center", mt: 10 }}>
							<Button
								variant="contained"
								sx={{
									background: "#FAFAFA ",
									border: "1px solid #E1E1E1",
									borderRadius: "10px",
									width: "136px",
									height: "54px",
									marginLeft: "10px",
									color: "black",
								}}
								onClick={handleEditAnswer}
							>
								수정하기
							</Button>
							<Button
								variant="contained"
								sx={{
									background: "#3B3B3B ",
									border: "1px solid #E1E1E1",
									borderRadius: "10px",
									width: "136px",
									height: "54px",
									marginLeft: "10px",
								}}
								onClick={handleReply}
							>
								답변하기
							</Button>
						</Grid>
					</Grid>
				</div>
			</Stack>
		</>
	);
};

export default UserInfo;
