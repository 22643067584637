// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.date-picker-container {
  display: flex;
  width: 250%;
  justify-content: space-between;
}
.date-picker-container .react-datepicker {
  display: flex;
}
.date-picker-container .react-datepicker-wrapper {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: 1px solid #e1e1e1;
}
.date-picker-container .react-datepicker-wrapper .react-datepicker__input-container {
  width: fit-content;
  height: 100%;
  width: 100%;
  border-radius: 10px;
}
.date-picker-container .react-datepicker-wrapper .react-datepicker__input-container .button,
.date-picker-container .react-datepicker-wrapper .react-datepicker__input-container input,
.date-picker-container .react-datepicker-wrapper .react-datepicker__input-container select,
.date-picker-container .react-datepicker-wrapper .react-datepicker__input-container textarea {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  background-color: rgb(250, 250, 250);
  font-size: 16px;
  padding: 10px;
}

.hash-tag-container {
  width: 100%;
  display: flex;
  margin-bottom: 8px;
  align-items: center;
}
.hash-tag-container .hash-tag-label {
  width: 29.5%;
  color: black;
  font-weight: 600;
  font-size: 16px;
}
.hash-tag-container .hash-tag-content {
  width: 78.5%;
}`, "",{"version":3,"sources":["webpack://./src/components/phase2/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,WAAA;EACA,8BAAA;AACF;AACE;EACE,aAAA;AACJ;AAEE;EACE,WAAA;EACA,YAAA;EACA,mBAAA;EACA,yBAAA;AAAJ;AAEI;EACE,kBAAA;EACA,YAAA;EACA,WAAA;EACA,mBAAA;AAAN;AAEM;;;;EAIE,WAAA;EACA,YAAA;EACA,mBAAA;EACA,qCAAA;EACA,oCAAA;EACA,eAAA;EACA,aAAA;AAAR;;AAMA;EACE,WAAA;EACA,aAAA;EACA,kBAAA;EACA,mBAAA;AAHF;AAKE;EACE,YAAA;EACA,YAAA;EACA,gBAAA;EACA,eAAA;AAHJ;AAME;EACE,YAAA;AAJJ","sourcesContent":[".date-picker-container {\n  display: flex;\n  width: 250%;\n  justify-content: space-between;\n\n  .react-datepicker {\n    display: flex;\n  }\n\n  .react-datepicker-wrapper {\n    width: 100%;\n    height: 100%;\n    border-radius: 10px;\n    border: 1px solid #e1e1e1;\n\n    .react-datepicker__input-container {\n      width: fit-content;\n      height: 100%;\n      width: 100%;\n      border-radius: 10px;\n\n      .button,\n      input,\n      select,\n      textarea {\n        width: 100%;\n        height: 100%;\n        border-radius: 10px;\n        border: 1px solid rgba(0, 0, 0, 0.23);\n        background-color: rgb(250, 250, 250);\n        font-size: 16px;\n        padding: 10px;\n      }\n    }\n  }\n}\n\n.hash-tag-container {\n  width: 100%;\n  display: flex;\n  margin-bottom: 8px;\n  align-items: center;\n\n  .hash-tag-label {\n    width: 29.5%;\n    color: black;\n    font-weight: 600;\n    font-size: 16px;\n  }\n\n  .hash-tag-content {\n    width: 100% - 21.5%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
