export const ROUTE_API = {
	SALES_HISTORY: "sales_history",
	USER_SETTING: "user_setting",
	USAGE_STAR: "usage_star",
	FAVORITE: "favorite",
	PENALTY: "penalty",
	BANNER_ASP: "banner_asp",
	HASHTAG_ASP: "hashtag_asp",
	NICE: "nice_info",
	ASP_MEMBER: "asp_member",
	GET_LIST_HASHTAG_ASP: "hashtag_asp",
	GET_TOKEN_CONVERSATION: "conversation/get_token_agora_rtm",
	AUTH_AUTO_LOGIN: "auth/auto_login",
	BANNER: "banner",
	HASHTAG: "hashtag",
	SYSTEM_SETTING: "system_setting",
	PAYMENT: "payment",
	REVENUE: "revenue",
	INTRO_ASP: "intro_asp",
};

export const ROUTE = {
	HOME: "/",
	POPULAR: "/popular",
	CREATE_POPULAR: "/popular/create",
	EDIT_POPULAR: "/popular/edit/:id",
	REPRESENT: "/represent",
	CREATE_REPRESENT: "/represent/create",
	SET_ORDER_REPRESENT: "/represent/set_order",
	EDIT_REPRESENT: "/represent/edit/:id",
	BANNER: "/banner",
	CREATE_BANNER: "/banner/create",
	UPDATE_BANNER: "/banner/update/:id",
	HASHTAG: "/hashtag",
	CREATE_HASHTAG: "/hashtag/create",
	EDIT_HASHTAG: "/hashtag/edit/:id",
	HASHTAG_LOUNGE: "/hashtagLounge",
	CREATE_HASHTAG_LOUNGE: "/hashtagLounge/create",
	EDIT_HASHTAG_LOUNGE: "/hashtagLounge/edit/:id",
	CATEGORY: "/category",
	CREATE_CATEGORY: "/category/create",
	EDIT_CATEGORY: "/category/edit/:id",
	EMPLOYEE: "/employee",
	CREATE_EMPLOYEE: "/employee/create",
	UPDATE_EMPLOYEE: "/employee/update/:id",
	POST: "/post",
	INQUIRY: "/inquiry",
	INQUIRY_CHAT: "/inquiry/chat/:id",
	CREATE_INQUIRY: "/inquiry/create",
	ANSWER_INQUIRY: "/inquiry/answer/:id",
	EXCHANGE: "/exchange",
	CREATE_EXCHANGE: "/exchange/create",
	EDIT_EXCHANGE: "/exchange/edit/:id",
	USER_EXCHANGE: "/exchange/user/:user_id",
	DEFAULT_MESSAGE: "/default_message",
	CREATE_DEFAULT_MESSAGE: "/default_message/create",
	EDIT_DEFAULT_MESSAGE: "/default_message/edit/:id",
	DETAIL_DEFAULT_MESSAGE: "/default_message/detail/:id",
	POLICY: "/policy",
	CREATE_POLICY: "/policy/create",
	EDIT_POLICY: "/policy/edit/:id",
	DETAIL_POLICY: "/policy/detail/:id",
	MEMBER: "/member",
	CREATE_MEMBER: "/member/create",
	UPDATE_MEMBER: "/member/update/:id",
	LIST_POST_MEMBER: "/member/list-post/:id",
	DETAIL_MEMBER: "/member/detail/:id",
	FOLLOW_MEMBER: "/member/follow/:id",
	UPDATE_MEMBER2: "/member/update/:id",
	POST_DETAIL_MEMBER: "/member/post/detail/:id",
	POST_MEMBER: "/member/post/:user_id",
	USER: "/user/:id",
	CONVERSATION_USER: "/user/conversation/:user_id",
	FOLLOW: "/follow",
	FOLLOWER: "/follow/follower/:follower_id",
	FOLLOWING: "/follow/following/:following_id",
	PUSH_LOG: "/push_log",
	SMS_PUSH: "/sms_push",
	PUSH_NOTIFICATION: "/push_noti",
	AGENCY_PUSH_NOTIFICATION: "/agency_push_noti",
	SMS_LOG: "/sms_log",
	INTERMEDIARY_FEE: "/intermediary_fee",
	UPDATE_PUSH_LOG: "/push_log/update/:id",
	CALL_HISTORY: "/call_history",
	CALL_HISTORY_DETAIL: "/call_history/call/:id",
	REPORT: "/report",
	POST_CELEB: "/postceleb",
	CONVERSATION_CELEB: "/conversationceleb",
	ACCEPT_CELEB: "/Accept",
	FOLLOW_CELEB: "/followCeleb",
	SUBSCRIPTION_CELEB: "/supcriptionCeleb",
	BLOCK_USER: "/blockuser",
	ACCEPT_IMAGE_PROFILE: "/accept_img_profile",
	USER_LIST: "/listUser",
	DELETE_ACCOUNT: "/delete_account",
	TERMS_OF_SERVICE: "/terms_of_service",
	CELEB_WITHDRAW: "/celeb_withdraw",
	INAPP_PAYMENT_WITHDRAW: "/inapp_payment_withdraw",
	STAR_LOG: "/star_log",
	GIFT_LOG: "/gift_log",
	AGENCY: "/agency",
	CREATE_AGENCY: "/agency/create",
	EDIT_AGENCY: "/agency/edit/:id",
	BANNER_ASP: "/banner_asp",
	HASHTAG_ASP: "/hashtag_asp",
	CREATE_HASHTAG_ASP: "/hashtag_asp/create",
	EDIT_HASHTAG_ASP: "/hashtag_asp/edit/:id",
	REELS_ASP: "/reels_asp",
	REELS: "/reels",
	CREATE_BANNER_ASP: "/banner_asp/create",
	UPDATE_BANNER_ASP: "/banner_asp/update/:id",
	DAILY_SALE_ASP: "/dailySaleAsp",
	DAILY_SALE_ASP_DETAIL: "/dailySaleAspDetail",
	MONTHLY_SALES_ASP: "/monthlySaleAsp",
	QnA_ASP: "/qnAAsp",
	Qna_ASP_CRUD: "/qnAAsp/answer/:id",
	STAR_LOG_ASP: "/starLogASP",
	COIN_LOG_ASP: "/coinLogASP",
	VIDEO_CHAT_LOG_ASP: "/videoChatLogASP",
	LIST_USER: "/listUser",
	List_User_ASP: "/listUserASP",
	CHAT_LOG_ASP: "/ChatLogASP",
	PLUSKIT_PURCHASE_LOG_ASP: "/PluskitPurchaseLogASP",
	Profile_Image_ASP: "/profileImageASP",
	Report_User_ASP: "/reportUserASP",
	VERSION: "/version",
	INS_USER: "/ins_user",
	CELEB_REQUEST_WITHDRAW: "/celeb_request_withdraw",
	AGENCY_WITHDRAW: "/agency_withdraw",
	AGENCY_REQUEST_WITHDRAW: "/agency_request_withdraw",
	PRIVACY: "/privacy",
	NOTICE: "/notice",
	CREATE_NOTICE: "/notice/create",
	EDIT_NOTICE: "/notice/edit/:id",
	APPROVE_POST: "/approve_post",
	FAQ: "/faq",
	CREATE_FAQ: "/faq/create",
	EDIT_FAQ: "/faq/edit/:id",
	QNA: "/qna",
	VIDEO_CALL_LOG: "/video_call_log",
	CHAT_LOG: "/chat_log",
	DISCOVERY: "/dicovery",
	CREATE_DISCOVERY: "/dicovery/create",
	SEARCH: "/search",
	CREATE_SEARCH: "/search/create",
	DEFAULT_MESS: "/default_mess",
	SETTING_DEFAULT_MESS: "/setting_default_mess",
	DEFAULT_MESS_2: "/default_mess_2",
	SETTING_DEFAULT_MESS_2: "/setting_default_mess_2",
	AUTO_MESS_ASP: "/auto_mess_asp",
	SETTING_AUTO_MESS_ASP: "/setting_auto_mess_asp",
	MONTHLY_SALE: "/monthlySale",
	DAILY_SALE: "/dailySale",
	PURCHASE_HISTORY: "/purchaseHistory",
	SUBSCRIBER_HISTORY: "/subscriberHistory",
	WEB_PURCHASE_HISTORY: "/web_purchase_history",
	ACCUMULATED_STAR_CELEB: "/accumulated_star_celeb",
	CELEB_CONNECTION_STATUS: "/celeb_connection_status",
	ACTIVITY_STATUS_CELEB_MANAGEMENT: "/activity_status_celeb_management",
	ASP_PLUSKIT_CELEB_MANAGEMENT: "/asp_pluskit_celeb_management",
	ADMIN_UPDATE_DONATE_MONEY: "/admin_update_donate_money",
	INTRO_ASP: "/intro_asp",
	CREATE_INTRO_ASP: "/intro_asp/create",
	UPDATE_INTRO_ASP: "/intro_asp/update/:id",
	TIME_ZONE_ACCESS_STATISTICS: "/time-zone-access-statistics",
	DAILY_ACCESS_STATISTICS: "/daily-access-statistics",
	ACCESS_STATISTICS_DETAIL: "/access-statistics-detail",
	ON_OFF_PLUS_KIT_MANAGEMENT: "/on-off-pluskit-management",
	VIDEO_CALL_ERROR_STATISTICS: "/video-call-error-statistics",
	GIFT_REGISTRATION_MANAGEMENT: "/gift-registration-management",
	PLUSKIT_VIDEO_MANAGEMENT: "/pluskit_video_management",
	// ASP_TESTING_ACCOUNT_MANAGEMENT: "/asp_testing_account_management",
	ASP_ACCOUNT_TESTING_MANAGEMENT: "/accountTestingManagement",
};
