import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import { Field } from "formik";
import { useState } from "react";
import { headerFilter } from "src/util/data";
import { DataRangePickerField, InputField, SelectField } from "../Fields";
import FilterInputWrapper from "../NewLayout/FilterInputWrapper";
import "../style.css";

const ManagepostCeleb = () => {
	const [DateRange, setDateRange] = useState([]);
	const { memberInfo, reason } = headerFilter.ListCointUserProfile;
	return (
		<FormGroup>
			<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
				<Grid item xs={6}>
					{/* member info */}
					<FilterInputWrapper label="회원검색">
						<Grid item xs={4} className="mr-2">
							<Field name="memberInfoSelect" component={SelectField} data={memberInfo} />
						</Grid>
						<Grid item xs={8}>
							<Field
								name="memberInfoInput"
								component={InputField}
								placeholder="입력해주세요"
								className="inputFilter"
							/>
						</Grid>
					</FilterInputWrapper>

					{/* time */}
					<FilterInputWrapper label="기간검색">
						<Field
							name="time__data"
							component={DataRangePickerField}
							DateRange={DateRange}
							setDateRange={setDateRange}
						/>
					</FilterInputWrapper>
				</Grid>
				<Grid item xs={6}>
					{/* reason */}
					<FilterInputWrapper label="사유">
						<Field name="reason" component={SelectField} data={reason} />
					</FilterInputWrapper>
				</Grid>
			</Grid>
		</FormGroup>
	);
};

export default ManagepostCeleb;
