import axiosClient from "../axiosClient";
const HOSTNAME = process.env.REACT_APP_API;
const table = "sticker";
const token = JSON.parse(localStorage.getItem("token"));

const Api = {
	getListSticker: (fields, filter, limit, page) => {
		const url = `${HOSTNAME}/${table}?fields=${fields}&limit=${limit}&page=${page}&filter=${filter}`;
		return axiosClient.get(url);
	},
	createSticker: (body) => {
		const url = `${HOSTNAME}/${table}/create`;
		return axiosClient.post(url, body, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
	},
	updateSticker: (body, id) => {
		const url = `${HOSTNAME}/${table}/${id}`;
		return axiosClient.put(url, body, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
	},
};

export default Api;
