import React, { useEffect, useState, useContext } from "react";
import { updateToCeleb } from "src/api/UserApi";
import PostApi from "src/api/MemberApi";
import { LoadingContext } from "src/providers/LoadingProvider";

import classes from "./NormalToCeleb.module.scss";
import { showError, showSuccess } from "src/util/error";
function NormalToCeleb({ setReloadApiAfterChange, ...props }) {
	const { data } = { ...props };
	const [payload, setPayload] = useState();
	const [state, setState] = useState();
	const API = PostApi;
	const { showLoading, hideLoading } = useContext(LoadingContext);

	const handleToCeleb = async () => {
		showLoading();
		const res = await updateToCeleb(data?.id, payload);
		if (res?.code === 200) {
			alert("register success");
			fetchAll();
		}
		hideLoading();
	};
	// handleToCeleb();
	// const handleChange = (name, data) => {
	// 	setPayload((pre) => ({ ...pre, [name]: data }));
	// };
	const fetchAll = async () => {
		try {
			const response = await API.getAcceptCeleb(
				1,
				10,
				JSON.stringify({ register_status: "PENDING", user_type: "CELEBRITY", id: data?.id }),
				{ column: "", asc: false }
			);
			setState(response?.results?.objects?.rows[0]);
		} catch (error) {}
	};
	const handleCelebRequest = async (register_status, id) => {
		try {
			showLoading();
			await API.appcetManyCeleb(JSON.stringify([id]), register_status);
			setReloadApiAfterChange(true);
			showSuccess("success", "Successfully");
			fetchAll();
			hideLoading();
		} catch (error) {
			showError();
		}
	};
	useEffect(() => {
		setPayload({
			nickname: data?.nickname,
			identified_id: data?.identified_id,
			email: data?.email,
			birthday: 1134320399000,
			sex: data?.sex,
			phone_prefix: "82",
			id: data?.id,
			register_status: "PENDING",
			user_type: "CELEBRITY",
			instagram_link: "www.instagram.com/",
			agency_username: null,
		});
	}, [data]);

	return (
		<div className={classes.wrapper}>
			{/* <div className={classes.container}>
				<span>Phone</span>
				<input
					className={classes.inputPhone}
					onChange={(e) => handleChange("phone", e.target.value)}
					type="number"
					value={payload?.phone}
				/>
			</div> */}

			<div>
				{state ? (
					<button onClick={() => handleCelebRequest("ACCEPT", state?.id)}>
						Confirm Accept Celeb
					</button>
				) : (
					<button onClick={() => handleToCeleb()}>Register To Celeb</button>
				)}
			</div>
		</div>
	);
}

export default NormalToCeleb;
