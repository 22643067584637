import React from "react";
import { ROUTE } from "src/util/ROUTER";

const Dashboard = React.lazy(() => import("./Pages/Dashboard/Dashboard"));

const EmployeeList = React.lazy(() => import("./Pages/employee/EmployeeList"));
const EmployeeCRUD = React.lazy(() => import("./Pages/employee/EmployeeCRUD"));

const InquiryCRUD = React.lazy(() => import("./Pages/Inquiry/InquiryCRUD"));

const ConversationList = React.lazy(() => import("./Pages/ManageCeleb/ChatCeleb"));

const NotificationList = React.lazy(() => import("./Pages/Notification/NotificationList"));
const SMSPush = React.lazy(() => import("./Pages/Managements/Function/SMSPush"));
const NotificationCRUD = React.lazy(() => import("./Pages/Notification/NotificationCRUD"));
const AngencyPushNotification = React.lazy(() => import("./Pages/Notification/AgencyNotification"));
const SMSLog = React.lazy(() => import("./Pages/Managements/Function/SMSLog"));

const ContentList = React.lazy(() => import("./Pages/Content/ContentList"));
const Version = React.lazy(() => import("./Pages/Version/Version"));

const ContentCRUD = React.lazy(() => import("./Pages/Content/ContentCRUD"));
const ContentDetail = React.lazy(() => import("./Pages/Content/ContentDetail"));
const Banner = React.lazy(() => import("./Pages/Banner/Banner"));
const BannerCRUD = React.lazy(() => import("./Pages/Banner/BannerCRUD"));
const DefaultMessList = React.lazy(() => import("./Pages/DefaultMessage/DefaultMessList"));
const DefaultMessCRUD = React.lazy(() => import("./Pages/DefaultMessage/DefaultMessCRUD"));
const DefaultMessDetail = React.lazy(() => import("./Pages/DefaultMessage/DefaultMessDetail"));

const PostCelebList = React.lazy(() => import("./Pages/ManageCeleb/PostCelebList"));
const AcceptCelebList = React.lazy(() => import("./Pages/ManageCeleb/AcceptCelebList"));
const FollowCelebList = React.lazy(() => import("./Pages/ManageCeleb/FollowCeleb"));
const SubcriptionCelebList = React.lazy(() => import("./Pages/ManageCeleb/SubcriptionCeleb"));
const MonthLySaleListList = React.lazy(() =>
	import("./Pages/SaleStatistic/MonthlySale/MonthLySaleList")
);
const DailySaleListList = React.lazy(() => import("./Pages/SaleStatistic/DailySale/DailySaleList"));
const PurchaseHistoryList = React.lazy(() =>
	import("./Pages/SaleStatistic/PurchaseHistory/PurchaseHistoryList")
);
const SubscriberHistoryList = React.lazy(() =>
	import("./Pages/SaleStatistic/SubscriberHistory/SubscriberHistoryList")
);
const WebPurchaseHistoryList = React.lazy(() =>
	import("./Pages/SaleStatistic/WebPurchaseHistory/WebPurchaseHistoryList")
);
const StarStatisticCelebList = React.lazy(() =>
	import("./Pages/SaleStatistic/StarSataticCeleb/StarStatisticCelebList")
);
const CelebConnectionStatus = React.lazy(() =>
	import("./Pages/SaleStatistic/CelebConnectionStatus/index")
);
const ActivityStatusCelebManagement = React.lazy(() =>
	import("./Pages/ManageCeleb/ActivityStatusCelebManagement/index")
);
const ASPPluskitCelebManagement = React.lazy(() =>
	import("./Pages/ManageCeleb/ASPPluskitCelebManagement/index")
);
const ASPAccountTestingManagement = React.lazy(() =>
	import("./Pages/ManageCeleb/ASPAccountTestingManagement/index")
);
const BlockUserList = React.lazy(() => import("./Pages/User Management/BlockUser"));
const ReportUserList = React.lazy(() => import("./Pages/User Management/ReportUser"));
const ProfileImageList = React.lazy(() => import("./Pages/User Management/ProfileImage"));
const ApprovePost = React.lazy(() => import("./Pages/User Management/ApprovePost1"));
const Intermediaryfee = React.lazy(() => import("./Pages/ManageCeleb/Intermediaryfee"));
const UserList = React.lazy(() => import("./Pages/User Management/ListUser"));
const ListUserInstagram = React.lazy(() => import("./Pages/User Management/ListUserInstagram"));
const SettingDefaultMess = React.lazy(() => import("./Pages/ManageCeleb/SettingDefaultMess"));
const DefaultMess = React.lazy(() => import("./Pages/ManageCeleb/DefaultMess"));
const SettingDefaultMessII = React.lazy(() => import("./Pages/ManageCeleb/SettingDefaultMessII"));
const DefaultMessII = React.lazy(() => import("./Pages/ManageCeleb/DefaultMessII"));
const DeleteUser = React.lazy(() => import("./Pages/User Management/DeleteUser"));
const Term = React.lazy(() => import("./Pages/Board/Terms"));
const PersonalInfomation = React.lazy(() => import("./Pages/Board/PersonalInfomation"));
const Notice = React.lazy(() => import("./Pages/Board/Notice/Notice"));
const NoticeCrud = React.lazy(() => import("./Pages/Board/Notice/NoticeCrud"));
const Inquiry = React.lazy(() => import("./Pages/Board/Inquiry/Inquiry"));
const FAQ = React.lazy(() => import("./Pages/Board/FAQ/FAQ"));
const FAQCurd = React.lazy(() => import("./Pages/Board/FAQ/FAQCrud"));

// const HashtagList = React.lazy(() => import("./Pages/ManageCeleb/Hashtag/HashtagList"));
// const HashtagCRUD = React.lazy(() => import("./Pages/ManageCeleb/Hashtag/HashtagCRUD"));

const HashtagList = React.lazy(() => import("./Pages/Hashtag/HashtagList"));
const HashtagCRUD = React.lazy(() => import("./Pages/Hashtag/HashtagCRUD"));

const HashtagLoungeList = React.lazy(() => import("./Pages/HashtagLounge/HashtagList"));
const HashtagLoungeCRUD = React.lazy(() => import("./Pages/HashtagLounge/HashtagCRUD"));
const HashtagASPList = React.lazy(() => import("./Pages/HashtagASP/HashtagASPList"));
const HashtagASPCRUD = React.lazy(() => import("./Pages/HashtagASP/HashtagASPCRUD"));

const CategoryList = React.lazy(() => import("./Pages/Category/CaterogyList"));
const CategoryCRUD = React.lazy(() => import("./Pages/Category/CaterogyCRUD"));
const VideoCallLog = React.lazy(() => import("./Pages/ChatManagement/VideoCallLog"));
const ChatLog = React.lazy(() => import("./Pages/ChatManagement/ChatLog"));
const CelebWithdraw = React.lazy(() => import("./Pages/PaymentManagement/CelebWithdraw"));
const InappPayment = React.lazy(() => import("./Pages/PaymentManagement/InappPayment"));
const StarLog = React.lazy(() => import("./Pages/StarManagement/StarLog"));
const GiftLog = React.lazy(() => import("./Pages/StarManagement/GiftLog"));
const CelebRequestWithdraw = React.lazy(() =>
	import("./Pages/PaymentManagement/CelebRequestWithdraw")
);
const AgencyWithdraw = React.lazy(() => import("./Pages/PaymentManagement/AgencyWithdraw"));
const AgencyRequestWithdraw = React.lazy(() =>
	import("./Pages/PaymentManagement/AgencyRequestWithdraw")
);
const DiscoveryList = React.lazy(() => import("./Pages/Representative/Discover/Discover"));
const DiscoveryCRUD = React.lazy(() => import("./Pages/Representative/Discover/DiscoverCrud"));
const PoupularList = React.lazy(() => import("./Pages/Representative/Popular/Search"));
const PoupularCRUD = React.lazy(() => import("./Pages/Representative/Popular/SearchCrud"));
const RecommendList = React.lazy(() => import("./Pages/Representative/Recommend/Search"));
const RecommendCRUD = React.lazy(() => import("./Pages/Representative/Recommend/SearchCrud"));
const ListCelebSetOrder = React.lazy(() => import("./Pages/Representative/Recommend/ListUser"));
const SearchList = React.lazy(() => import("./Pages/Representative/Search/Search"));
const SearchCRUD = React.lazy(() => import("./Pages/Representative/Search/SearchCrud"));
const Agency = React.lazy(() => import("./Pages/Managements/Agency"));
const AgencyCurd = React.lazy(() => import("./Pages/Managements/Agency/AgencyCrud"));

const BannerASP = React.lazy(() => import("./Pages/BannerAsp/BannerAsp"));
const BannerASPCRUD = React.lazy(() => import("./Pages/BannerAsp/BannerAspCRUD"));
const ReelsASP = React.lazy(() => import("./Pages/ReelsAsp/ReelsAsp"));
const Reels = React.lazy(() => import("./Pages/Reels/Reels"));
const DailySaleASP = React.lazy(() => import("./Pages/PaymentManagementASP/DailySaleASP"));
const DailySaleASPDetail = React.lazy(() =>
	import("./Pages/PaymentManagementASP/DailySaleASPDetail/DailySaleASPDetail")
);
const MonthlySaleASP = React.lazy(() => import("./Pages/PaymentManagementASP/MonthlySaleASP"));
const InquiryASP = React.lazy(() => import("./Pages/BoardASP/InquiryASP/InquiryASP"));
const InquiryASPCRUD = React.lazy(() => import("./Pages/BoardASP/InquiryASP/InquiryASPCRUD"));
const StarLogASP = React.lazy(() => import("./Pages/LogManagement/StarLogASP"));
const CoinLogASP = React.lazy(() => import("./Pages/LogManagement/CoinLogASP"));
const ListUserASP = React.lazy(() => import("./Pages/UserManagementASP/ListUserASP"));
const VideoChatLogASP = React.lazy(() => import("./Pages/LogManagement/VideoChatLogASP"));
const ChatLogASP = React.lazy(() => import("./Pages/LogManagement/ChatLogASP"));
const ProfileImageASP = React.lazy(() => import("./Pages/UserManagementASP/ProfileImageASP"));
const ReportUserASP = React.lazy(() => import("./Pages/UserManagementASP/ReportUserASP"));
const SettingAutoMessASP = React.lazy(() => import("./Pages/ManageCeleb/SettingAutoMessASP"));
const AutoMessASP = React.lazy(() => import("./Pages/ManageCeleb/AutoMessASP"));
const IntroASP = React.lazy(() => import("./Pages/IntroASP"));
const PluskitVideoManagement = React.lazy(() =>
	import("./Pages/PluskitVideoManagement/PluskitVideoManagement")
);
const IntroAspCRUD = React.lazy(() => import("./Pages/IntroASP/IntroAspCRUD"));
const TimeZoneAccessStatistics = React.lazy(() =>
	import("./Pages/StatisticsManagement/TimeZoneAccessStatistics/index")
);
const DailyAccessStatistics = React.lazy(() =>
	import("./Pages/StatisticsManagement/DailyAccessStatistics/index")
);
const AccessStatisticsDetail = React.lazy(() =>
	import("./Pages/StatisticsManagement/AccessStatisticsDetail/index")
);
const OnOffPlusKitManagement = React.lazy(() =>
	import("./Pages/ManageCeleb/OnOffPlusKitManagement/index")
);
const VideoCallErrorStatistics = React.lazy(() =>
	import("./Pages/StatisticsManagement/VideoCallErrorStatistics/index")
);
const GiftRegistrationManagement = React.lazy(() =>
	import("./Pages/GiftRegistrationManagement/index")
);

const PluskitPurchaseLogASP = React.lazy(() =>
	import("./Pages/LogManagement/PluskitPurchaseLogASP")
);

const routes = [
	{ path: ROUTE.HOME, element: <Dashboard /> },
	{ path: ROUTE.POPULAR, element: <PoupularList /> },
	{ path: ROUTE.CREATE_POPULAR, element: <PoupularCRUD /> },
	{ path: ROUTE.EDIT_POPULAR, element: <PoupularCRUD /> },

	{ path: ROUTE.REPRESENT, element: <RecommendList /> },
	{ path: ROUTE.CREATE_REPRESENT, element: <RecommendCRUD /> },
	{ path: ROUTE.SET_ORDER_REPRESENT, element: <ListCelebSetOrder /> },
	{ path: ROUTE.EDIT_REPRESENT, element: <RecommendCRUD /> },

	{ path: ROUTE.BANNER, element: <Banner /> },
	{ path: ROUTE.CREATE_BANNER, element: <BannerCRUD /> },
	{ path: ROUTE.UPDATE_BANNER, element: <BannerCRUD /> },

	{ path: ROUTE.BANNER_ASP, element: <BannerASP /> },
	{ path: ROUTE.CREATE_BANNER_ASP, element: <BannerASPCRUD /> },
	{ path: ROUTE.UPDATE_BANNER_ASP, element: <BannerASPCRUD /> },
	{ path: ROUTE.REELS_ASP, element: <ReelsASP /> },

	{ path: ROUTE.HASHTAG, element: <HashtagList /> },
	{ path: ROUTE.CREATE_HASHTAG, element: <HashtagCRUD /> },
	{ path: ROUTE.EDIT_HASHTAG, element: <HashtagCRUD /> },

	{ path: ROUTE.HASHTAG_LOUNGE, element: <HashtagLoungeList /> },
	{
		path: ROUTE.CREATE_HASHTAG_LOUNGE,
		
		element: <HashtagLoungeCRUD />,
	},
	{
		path: ROUTE.EDIT_HASHTAG_LOUNGE,
		
		element: <HashtagLoungeCRUD />,
	},

	{ path: ROUTE.HASHTAG_ASP, element: <HashtagASPList /> },
	{
		path: ROUTE.CREATE_HASHTAG_ASP,
		
		element: <HashtagASPCRUD />,
	},
	{ path: ROUTE.EDIT_HASHTAG_ASP, element: <HashtagASPCRUD /> },

	{ path: ROUTE.CATEGORY, element: <CategoryList /> },
	{ path: ROUTE.CREATE_CATEGORY, element: <CategoryCRUD /> },
	{ path: ROUTE.EDIT_CATEGORY, element: <CategoryCRUD /> },

	{ path: ROUTE.EMPLOYEE, element: <EmployeeList /> },
	{ path: ROUTE.CREATE_EMPLOYEE, element: <EmployeeCRUD /> },
	{ path: ROUTE.UPDATE_EMPLOYEE, element: <EmployeeCRUD /> },

	{ path: ROUTE.CREATE_INQUIRY, element: <InquiryCRUD /> },
	{ path: ROUTE.ANSWER_INQUIRY, element: <InquiryCRUD /> },

	{ path: ROUTE.DEFAULT_MESSAGE, element: <DefaultMessList /> },
	{ path: ROUTE.CREATE_DEFAULT_MESSAGE, element: <DefaultMessCRUD /> },
	{ path: ROUTE.EDIT_DEFAULT_MESSAGE, element: <DefaultMessCRUD /> },
	{ path: ROUTE.DETAIL_DEFAULT_MESSAGE, element: <DefaultMessDetail /> },

	{ path: ROUTE.POLICY, element: <ContentList /> },
	{ path: ROUTE.VERSION, element: <Version /> },

	{ path: ROUTE.CREATE_POLICY, element: <ContentCRUD /> },
	{ path: ROUTE.EDIT_POLICY, element: <ContentCRUD /> },
	{ path: ROUTE.DETAIL_POLICY, element: <ContentDetail /> },

	{ path: ROUTE.CONVERSATION_USER, element: <ConversationList /> },

	{ path: ROUTE.PUSH_LOG, element: <NotificationList /> },
	{ path: ROUTE.SMS_PUSH, element: <SMSPush /> },
	{ path: ROUTE.PUSH_NOTIFICATION, element: <NotificationCRUD /> },
	{ path: ROUTE.AGENCY_PUSH_NOTIFICATION, element: <AngencyPushNotification /> },
	{ path: ROUTE.SMS_LOG, element: <SMSLog /> },
	{ path: ROUTE.INTERMEDIARY_FEE, element: <Intermediaryfee /> },
	{ path: ROUTE.UPDATE_PUSH_LOG, element: <NotificationCRUD /> },

	{ path: ROUTE.REPORT, element: <ReportUserList /> },

	// new layout cms
	{ path: ROUTE.POST_CELEB, element: <PostCelebList /> },
	{
		path: ROUTE.CONVERSATION_CELEB,
		element: <ConversationList />,
		
	},
	{ path: ROUTE.ACCEPT_CELEB, element: <AcceptCelebList /> },
	{ path: ROUTE.FOLLOW_CELEB, element: <FollowCelebList /> },
	{
		path: ROUTE.SUBSCRIPTION_CELEB,
		element: <SubcriptionCelebList />,
		
	},
	{ path: ROUTE.BLOCK_USER, element: <BlockUserList /> },
	{
		path: ROUTE.ACCEPT_IMAGE_PROFILE,
		element: <ProfileImageList />,
		
	},
	{ path: ROUTE.LIST_USER, element: <UserList /> },
	{ path: ROUTE.DELETE_ACCOUNT, element: <DeleteUser /> },
	{ path: ROUTE.TERMS_OF_SERVICE, element: <Term /> },
	{ path: ROUTE.CELEB_WITHDRAW, element: <CelebWithdraw /> },
	{
		path: ROUTE.INAPP_PAYMENT_WITHDRAW,
		element: <InappPayment />,
		
	},
	{ path: ROUTE.STAR_LOG, element: <StarLog /> },
	{ path: ROUTE.GIFT_LOG, element: <GiftLog /> },
	{
		path: ROUTE.INS_USER,
		element: <ListUserInstagram />,
		
	},

	{ path: ROUTE.AGENCY, element: <Agency /> },
	{ path: ROUTE.CREATE_AGENCY, element: <AgencyCurd /> },
	{ path: ROUTE.EDIT_AGENCY, element: <AgencyCurd /> },
	{
		path: ROUTE.CELEB_REQUEST_WITHDRAW,
		element: <CelebRequestWithdraw />,
		
	},
	{ path: ROUTE.AGENCY_WITHDRAW, element: <AgencyWithdraw /> },
	{ path: ROUTE.AGENCY_REQUEST_WITHDRAW, element: <AgencyRequestWithdraw /> },
	{ path: ROUTE.PRIVACY, element: <PersonalInfomation /> },
	{ path: ROUTE.NOTICE, element: <Notice /> },
	{ path: ROUTE.CREATE_NOTICE, element: <NoticeCrud /> },
	{ path: ROUTE.EDIT_NOTICE, element: <NoticeCrud /> },
	{ path: ROUTE.APPROVE_POST, element: <ApprovePost /> },
	{ path: ROUTE.FAQ, element: <FAQ /> },
	{ path: ROUTE.CREATE_FAQ, element: <FAQCurd /> },
	{ path: ROUTE.EDIT_FAQ, element: <FAQCurd /> },
	{ path: ROUTE.QNA, element: <Inquiry /> },
	{ path: ROUTE.VIDEO_CALL_LOG, element: <VideoCallLog /> },
	{ path: ROUTE.CHAT_LOG, element: <ChatLog /> },
	{ path: ROUTE.DISCOVERY, element: <DiscoveryList /> },
	{ path: ROUTE.CREATE_DISCOVERY, element: <DiscoveryCRUD /> },
	{ path: ROUTE.SEARCH, element: <SearchList /> },
	{ path: ROUTE.CREATE_SEARCH, element: <SearchCRUD /> },
	{ path: ROUTE.DEFAULT_MESS, element: <DefaultMess /> },
	{ path: ROUTE.SETTING_DEFAULT_MESS, element: <SettingDefaultMess /> },
	{ path: ROUTE.DEFAULT_MESS_2, element: <DefaultMessII /> },
	{ path: ROUTE.SETTING_DEFAULT_MESS_2, element: <SettingDefaultMessII /> },
	{ path: ROUTE.AUTO_MESS_ASP, element: <AutoMessASP /> },
	{ path: ROUTE.SETTING_AUTO_MESS_ASP, element: <SettingAutoMessASP /> },
	{ path: ROUTE.MONTHLY_SALE, element: <MonthLySaleListList /> },
	{ path: ROUTE.DAILY_SALE, element: <DailySaleListList /> },
	{ path: ROUTE.PURCHASE_HISTORY, element: <PurchaseHistoryList /> },
	{ path: ROUTE.SUBSCRIBER_HISTORY, element: <SubscriberHistoryList /> },
	{ path: ROUTE.WEB_PURCHASE_HISTORY, element: <WebPurchaseHistoryList /> },
	{ path: ROUTE.ACCUMULATED_STAR_CELEB, element: <StarStatisticCelebList /> },
	{ path: ROUTE.CELEB_CONNECTION_STATUS, element: <CelebConnectionStatus /> },
	{ path: ROUTE.ACTIVITY_STATUS_CELEB_MANAGEMENT, element: <ActivityStatusCelebManagement /> },
	{ path: ROUTE.DAILY_SALE_ASP, element: <DailySaleASP /> },
	{ path: ROUTE.DAILY_SALE_ASP_DETAIL, element: <DailySaleASPDetail /> },
	{ path: ROUTE.MONTHLY_SALES_ASP, element: <MonthlySaleASP /> },
	{ path: ROUTE.QnA_ASP, element: <InquiryASP /> },
	{ path: ROUTE.Qna_ASP_CRUD, element: <InquiryASPCRUD /> },
	{ path: ROUTE.STAR_LOG_ASP, element: <StarLogASP /> },
	{ path: ROUTE.COIN_LOG_ASP, element: <CoinLogASP /> },
	{ path: ROUTE.List_User_ASP, element: <ListUserASP /> },
	{ path: ROUTE.VIDEO_CHAT_LOG_ASP, element: <VideoChatLogASP /> },
	{ path: ROUTE.CHAT_LOG_ASP, element: <ChatLogASP /> },
	{ path: ROUTE.PLUSKIT_PURCHASE_LOG_ASP, element: <PluskitPurchaseLogASP /> },
	{ path: ROUTE.Profile_Image_ASP, element: <ProfileImageASP /> },
	{ path: ROUTE.Report_User_ASP, element: <ReportUserASP /> },
	{ path: ROUTE.REELS, element: <Reels /> },
	{ path: ROUTE.INTRO_ASP, element: <IntroASP /> },
	{ path: ROUTE.CREATE_INTRO_ASP, element: <IntroAspCRUD /> },
	{ path: ROUTE.UPDATE_INTRO_ASP, element: <IntroAspCRUD /> },
	{ path: ROUTE.ON_OFF_PLUS_KIT_MANAGEMENT, element: <OnOffPlusKitManagement /> },
	{ path: ROUTE.VIDEO_CALL_ERROR_STATISTICS, element: <VideoCallErrorStatistics /> },
	{ path: ROUTE.GIFT_REGISTRATION_MANAGEMENT, element: <GiftRegistrationManagement /> },
];

export const routesAntiKdisk = [
	{ path: ROUTE.DAILY_SALE_ASP, element: <DailySaleASP /> },
	{ path: ROUTE.DAILY_SALE_ASP_DETAIL, element: <DailySaleASPDetail /> },
	{ path: ROUTE.MONTHLY_SALES_ASP, element: <MonthlySaleASP /> },
	{ path: ROUTE.List_User_ASP, element: <ListUserASP /> },
	{ path: ROUTE.Profile_Image_ASP, element: <ProfileImageASP /> },
	{ path: ROUTE.Report_User_ASP, element: <ReportUserASP /> },
	{ path: ROUTE.ASP_ACCOUNT_TESTING_MANAGEMENT, element: <ASPAccountTestingManagement /> },
	{ path: ROUTE.QnA_ASP, element: <InquiryASP /> },
	{ path: ROUTE.Qna_ASP_CRUD, element: <InquiryASPCRUD /> },
	{ path: ROUTE.STAR_LOG_ASP, element: <StarLogASP /> },
	{ path: ROUTE.COIN_LOG_ASP, element: <CoinLogASP /> },
	{ path: ROUTE.VIDEO_CHAT_LOG_ASP, element: <VideoChatLogASP /> },
	{ path: ROUTE.CHAT_LOG_ASP, element: <ChatLogASP /> },
	{ path: ROUTE.PLUSKIT_PURCHASE_LOG_ASP, element: <PluskitPurchaseLogASP /> },
	{ path: ROUTE.BANNER_ASP, element: <BannerASP /> },
	{ path: ROUTE.CREATE_BANNER_ASP, element: <BannerASPCRUD /> },
	{ path: ROUTE.UPDATE_BANNER_ASP, element: <BannerASPCRUD /> },
	{ path: ROUTE.HASHTAG_ASP, element: <HashtagASPList /> },
	{ path: ROUTE.CREATE_HASHTAG_ASP, element: <HashtagASPCRUD /> },
	{ path: ROUTE.EDIT_HASHTAG_ASP, element: <HashtagASPCRUD /> },
	{ path: ROUTE.REELS_ASP, element: <ReelsASP /> },
	{ path: ROUTE.HOME, element: <DailySaleASP /> },
	{ path: ROUTE.DEFAULT_MESSAGE, element: <DefaultMessList /> },
	{ path: ROUTE.CREATE_DEFAULT_MESSAGE, element: <DefaultMessCRUD /> },
	{ path: ROUTE.EDIT_DEFAULT_MESSAGE, element: <DefaultMessCRUD /> },
	{ path: ROUTE.DETAIL_DEFAULT_MESSAGE, element: <DefaultMessDetail /> },
	{ path: ROUTE.POLICY, element: <ContentList /> },
	{ path: ROUTE.CREATE_POLICY, element: <ContentCRUD /> },
	{ path: ROUTE.EDIT_POLICY, element: <ContentCRUD /> },
	{ path: ROUTE.DETAIL_POLICY, element: <ContentDetail /> },
	{ path: ROUTE.VERSION, element: <Version /> },
	{ path: ROUTE.INTRO_ASP, element: <Version /> },
	{ path: ROUTE.CREATE_INTRO_ASP, element: <IntroAspCRUD /> },
	{ path: ROUTE.UPDATE_INTRO_ASP, element: <IntroAspCRUD /> },
	{ path: ROUTE.TIME_ZONE_ACCESS_STATISTICS, element: <TimeZoneAccessStatistics /> },
	{ path: ROUTE.DAILY_ACCESS_STATISTICS, element: <DailyAccessStatistics /> },
	{ path: ROUTE.ACCESS_STATISTICS_DETAIL, element: <AccessStatisticsDetail /> },
];

export const routesContentManager = [
	{ path: ROUTE.HOME, element: <IntroASP /> },
	{ path: ROUTE.INTRO_ASP, element: <IntroASP /> },
	{ path: ROUTE.CREATE_INTRO_ASP, element: <IntroAspCRUD /> },
	{ path: ROUTE.UPDATE_INTRO_ASP, element: <IntroAspCRUD /> },
	{ path: ROUTE.PLUSKIT_VIDEO_MANAGEMENT, element: <PluskitVideoManagement /> },
	{ path: ROUTE.ASP_PLUSKIT_CELEB_MANAGEMENT, element: <ASPPluskitCelebManagement /> },
];

export const routesFansomeASP = [
	{ path: ROUTE.HOME, element: <Dashboard /> },
	{ path: ROUTE.POPULAR, element: <PoupularList /> },
	{ path: ROUTE.CREATE_POPULAR, element: <PoupularCRUD /> },
	{ path: ROUTE.EDIT_POPULAR, element: <PoupularCRUD /> },

	// { path: ROUTE.POLICY, element: <ContentList /> },
	// { path: ROUTE.VERSION, element: <Version /> },

	{ path: ROUTE.CREATE_POLICY, element: <ContentCRUD /> },
	{ path: ROUTE.EDIT_POLICY, element: <ContentCRUD /> },
	{ path: ROUTE.DETAIL_POLICY, element: <ContentDetail /> },

	{ path: ROUTE.CONVERSATION_USER, element: <ConversationList /> },

	{ path: ROUTE.PUSH_LOG, element: <NotificationList /> },
	{ path: ROUTE.INTERMEDIARY_FEE, element: <Intermediaryfee /> },
	{ path: ROUTE.UPDATE_PUSH_LOG, element: <NotificationCRUD /> },
	{ path: ROUTE.REPORT, element: <ReportUserList /> },

	{ path: ROUTE.BLOCK_USER, element: <BlockUserList /> },
	{
		path: ROUTE.ACCEPT_IMAGE_PROFILE,
		element: <ProfileImageList />,
	},
	{ path: ROUTE.LIST_USER, element: <UserList /> },
	{ path: ROUTE.DELETE_ACCOUNT, element: <DeleteUser /> },
	{ path: ROUTE.TERMS_OF_SERVICE, element: <Term /> },
	{ path: ROUTE.STAR_LOG, element: <StarLog /> },
	{ path: ROUTE.GIFT_LOG, element: <GiftLog /> },

	{ path: ROUTE.AGENCY, element: <Agency /> },
	{ path: ROUTE.CREATE_AGENCY, element: <AgencyCurd /> },
	{ path: ROUTE.EDIT_AGENCY, element: <AgencyCurd /> },
	{ path: ROUTE.PRIVACY, element: <PersonalInfomation /> },
	{ path: ROUTE.NOTICE, element: <Notice /> },
	{ path: ROUTE.CREATE_NOTICE, element: <NoticeCrud /> },
	{ path: ROUTE.EDIT_NOTICE, element: <NoticeCrud /> },
	{ path: ROUTE.APPROVE_POST, element: <ApprovePost /> },
	{ path: ROUTE.FAQ, element: <FAQ /> },
	{ path: ROUTE.CREATE_FAQ, element: <FAQCurd /> },
	{ path: ROUTE.EDIT_FAQ, element: <FAQCurd /> },
	{ path: ROUTE.QNA, element: <Inquiry /> },
	{ path: ROUTE.VIDEO_CALL_LOG, element: <VideoCallLog /> },
	{ path: ROUTE.CHAT_LOG, element: <ChatLog /> },
	{ path: ROUTE.MONTHLY_SALE, element: <MonthLySaleListList /> },
	{ path: ROUTE.DAILY_SALE, element: <DailySaleListList /> },
	{ path: ROUTE.PURCHASE_HISTORY, element: <PurchaseHistoryList /> },
	{ path: ROUTE.SUBSCRIBER_HISTORY, element: <SubscriberHistoryList /> },
	{ path: ROUTE.WEB_PURCHASE_HISTORY, element: <WebPurchaseHistoryList /> },
	{ path: ROUTE.ACCUMULATED_STAR_CELEB, element: <StarStatisticCelebList /> },
	{ path: ROUTE.DAILY_ACCESS_STATISTICS, element: <DailyAccessStatistics /> },
	{ path: ROUTE.TIME_ZONE_ACCESS_STATISTICS, element: <TimeZoneAccessStatistics /> },
	{ path: ROUTE.ACCESS_STATISTICS_DETAIL, element: <AccessStatisticsDetail /> },

];

export default routes;
