import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

const RadioField = ({ field, form, data }) => {
	return (
		<RadioGroup
			value={field.value}
			onChange={(e) => form.setFieldValue(field.name, e.target.value)}
			defaultValue={data[0].value}
		>
			{data.map((item, index) => (
				<FormControlLabel
					key={index}
					value={item.value}
					control={<Radio color="default" />}
					label={item.label}
				/>
			))}
		</RadioGroup>
	);
};

export default RadioField;
