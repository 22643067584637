import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import { Field } from "formik";
import { useState } from "react";
import { headerFilter } from "src/util/data";
import { DataRangePickerField, SelectField } from "../Fields";
import FilterInputWrapper from "../NewLayout/FilterInputWrapper";
import "../style.css";

const ManagepostCeleb = () => {
	const [DateRange, setDateRange] = useState([]);
	const { typePost, pruchasePeriod, setting, searchTime } = headerFilter.ListFeedUserProfile;
	return (
		<FormGroup>
			<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
				<Grid item xs={6}>
					{/* type post  */}
					<FilterInputWrapper label="종류">
						<Field name="type_post" component={SelectField} data={typePost} />
					</FilterInputWrapper>
					{/* setting  */}
					<FilterInputWrapper label="설정">
						<Field name="setting" component={SelectField} data={setting} />
					</FilterInputWrapper>
				</Grid>
				<Grid item xs={6}>
					{/* purchase period */}
					<FilterInputWrapper label="구매기간">
						<Field name="purchase_period" component={SelectField} data={pruchasePeriod} />
					</FilterInputWrapper>
					{/* time */}
					<FilterInputWrapper label="기간검색">
						<Field name="search_time" component={SelectField} data={searchTime} />
						<div style={{ marginRight: "7px" }}></div>

						<Field
							name="time__data"
							component={DataRangePickerField}
							DateRange={DateRange}
							setDateRange={setDateRange}
						/>
					</FilterInputWrapper>
					{/* member info */}
					{/* <FilterInputWrapper label="회원정보">
						<Grid item xs={4} className="mr-2">
							<Field name="memberInfoSelect" component={SelectField} data={memberInfo} />
						</Grid>
						<Grid item xs={8}>
							<Field
								name="memberInfoInput"
								component={InputField}
								placeholder="입력해주세요"
								className="inputFilter"
							/>
						</Grid>
					</FilterInputWrapper> */}
				</Grid>
			</Grid>
		</FormGroup>
	);
};

export default ManagepostCeleb;
