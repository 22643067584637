import { CButton, CContainer, CInput, CModal, CModalBody, CModalFooter } from "@coreui/react";
import Logout from "@mui/icons-material/Logout";
import Settings from "@mui/icons-material/Settings";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { logout } from "src/SLice/Employee";
import SystemAdminApi from "src/api/SystemAdminApi";
import MultiLanguage from "src/components/phase2/MultiLanguage";
import i18n from "src/translation/i18n";
import { TheSidebarDefault } from "./index";
import { useNavigate, useLocation } from 'react-router-dom';

const IGNORE_LAYOUT_ROUTE = [
	'/login',
	'/listUserASPLog'
]

const TheLayout = ({ children }) => {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [modal, setModal] = useState(false);
	const [valueInput, setvalueInput] = useState("");
	const [currentValue, setcurrentValue] = useState(0);
	const [id, setid] = useState("");
	const [language, setlanguage] = useState(localStorage.getItem("i18nextLng") || "kr");
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const [isIgnoreLayout, setIsIgnoreLayout] = useState(false);

	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleLogout = () => {
		localStorage.removeItem("user");
		localStorage.removeItem("token");
		dispatch(logout());
		navigate("/login");
	};
	const toggle = () => {
		setModal(!modal);
	};
	const fetchAll = async () => {
		try {
			const res = await SystemAdminApi.getAll();
			setcurrentValue(res.results.objects.rows[0].value);
			setid(res.results.objects.rows[0].id);
		} catch (error) {
		}
	};
	const handleChangeValue = async (id, value) => {
		try {
			await SystemAdminApi.update({ value: value }, id);
			fetchAll();
			setvalueInput("");
		} catch (error) {
		}
	};
	const handleChangeLanguage = (e) => {
		localStorage.setItem("i18nextLng", e.target.value);
		setlanguage(e.target.value);
		i18n.changeLanguage(e.target.value);
	};

	useEffect(() => {
		setIsIgnoreLayout(IGNORE_LAYOUT_ROUTE.includes(location.pathname));
	}, [location.pathname])

	return (
		<>
			{!isIgnoreLayout ?
			<div className="c-app">
				{<TheSidebarDefault />}
				<div className="c-wrapper">
					<div className="c-body">
						<div
							style={{
								display: "flex",
								justifyContent: "flex-end",
								marginTop: "20px",
								alignItems: "center",
							}}
						>
							{" "}
							<MultiLanguage
								language={{ value: language, setValue: setlanguage }}
								handleChangeLanguage={handleChangeLanguage}
							/>
							<IconButton
								onClick={handleClick}
								size="large"
								sx={{ mr: 2 }}
								aria-controls={open ? "account-menu" : undefined}
								aria-haspopup="true"
								aria-expanded={open ? "true" : undefined}
							>
								<Settings />
							</IconButton>
						</div>

						<main className="c-main">
							<CContainer fluid>{children}</CContainer>
						</main>
					</div>
				</div>
				<Menu
					anchorEl={anchorEl}
					id="account-menu"
					open={open}
					onClose={handleClose}
					onClick={handleClose}
					slotProps={{
						elevation: 0,
						sx: {
							overflow: "visible",
							filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
							mt: 1.5,
							"& .MuiAvatar-root": {
								width: 32,
								height: 32,
								ml: -0.5,
								mr: 1,
							},
							"&:before": {
								content: '""',
								display: "block",
								position: "absolute",
								top: 0,
								right: 14,
								width: 10,
								height: 10,
								bgcolor: "background.paper",
								transform: "translateY(-50%) rotate(45deg)",
								zIndex: 0,
							},
						},
					}}
					transformOrigin={{ horizontal: "right", vertical: "top" }}
					anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
				>
					<MenuItem onClick={toggle}>
						<ListItemIcon>
							<Settings fontSize="small" />
						</ListItemIcon>
						System Settings
					</MenuItem>
					<MenuItem onClick={handleLogout}>
						<ListItemIcon>
							<Logout fontSize="small" />
						</ListItemIcon>
						Logout
					</MenuItem>
				</Menu>
				<CModal show={modal} onClose={toggle}>
					<CModalBody>
						<p>Current Value : {currentValue}</p>
						<CInput
							className="mt-2"
							placeholder="please enter your new value"
							value={valueInput}
							type="number"
							onChange={(e) => setvalueInput(e.target.value)}
						/>
					</CModalBody>
					<CModalFooter>
						<CButton color="primary" onClick={() => handleChangeValue(id, valueInput)}>
							Accept
						</CButton>{" "}
						<CButton color="secondary" onClick={toggle}>
							Cancel
						</CButton>
					</CModalFooter>
				</CModal>
			</div>
 			: <>{children}</>}
		</>
	);
};

export default TheLayout;
