import React, { useState, useEffect, useContext } from "react";
import "./style.css";
import Stack from "@mui/material/Stack";
import TheHeaderDefault from "src/components/phase2/NewLayout/TheHeaderDefault";
import { keyHeaderFilter, userType } from "src/util/data";
import useFilters from "src/hooks/useFilter";
import { LoadingContext } from "src/providers/LoadingProvider";
import Api from "src/api/subcriber";
import celebBadge from "src/assets/img/celeb_badge.svg";

import { convertTextToKorean, convertTimeZone } from "src/util/convert";
import DataTable from "src/components/phase2/DataTable";
import SupcriptionListFilter from "src/components/phase2/HeaderFilterInput/UserSupcriptionList";

const UserInfo = ({ data }) => {
	const arr = [];
	const [items, setItems] = useState([]);
	const [total, settotal] = useState(0);

	const { showLoading, hideLoading } = useContext(LoadingContext);

	const { LIMITED, currentPage, spages, filter } = useFilters();
	const columns = [
		{
			field: "id",
			hide: true,
			headerAlign: "center",
			sortable: false,
		},
		{
			field: "no",
			headerName: "No",
			headerAlign: "center",

			sortable: false,
		},
		{
			field: "title_subcribe",
			headerName: "구독 명칭",
			flex: 1,
			headerAlign: "center",
			sortable: false,
		},
		{
			field: "star",
			headerName: "구독 금액",
			flex: 1,

			headerAlign: "center",
			sortable: false,
		},
		{
			field: "subscriber_id",
			headerName: "구독자 아이디",
			headerAlign: "center",
			flex: 1,

			sortable: false,
		},
		{
			field: "subscriber_nickname",
			headerName: "구독자 닉네임",
			headerAlign: "center",
			flex: 1,
			renderCell: (params) => {
				return (
					<p>
						{params?.row?.subscriber_nickname}
						{data.user_type !== userType.CELEB && (
							<span>
								<img src={celebBadge} alt={celebBadge} />
							</span>
						)}
					</p>
				);
			},
			sortable: false,
		},
		{
			field: "subscriber_gender",
			headerName: "성별",
			flex: 1,

			headerAlign: "center",
			sortable: false,
		},

		{
			field: "start_date",
			headerName: "시작날짜",
			headerAlign: "center",
			flex: 1,

			sortable: false,
		},
		{
			field: "end_date",
			headerName: "종료날짜",
			headerAlign: "center",
			flex: 1,

			sortable: false,
		},
		{
			field: "status",
			headerName: "상태",
			headerAlign: "center",
			flex: 1,

			sortable: false,
		},
	];
	const handleChangePage = (e, value) => {
		currentPage.set(value);
	};
	const fetchAll = async () => {
		showLoading();
		try {
			const response = await Api.getAll(
				currentPage.value,
				LIMITED.value,
				JSON.stringify({
					...filter.value,
					[data.user_type === userType.CELEB ? "subscribed_id" : "user_id"]: data.id,
				})
			);
			settotal(response?.results?.objects.count);
			if (response.code === 200) {
				// xu li pagination
				let pages = Math.max(Math.ceil(response.results.objects.count / LIMITED.value), 1);
				spages.set(pages);
				response.results.objects.rows.map((val, idx) => {
					return arr.push({
						id: val?.id,
						no: idx + (currentPage.value - 1) * LIMITED.value + 1,
						title_subcribe: val?.pack_sub.title,
						star: val.price_curently,
						subscriber_id:
							data.user_type === userType.NORMAL
								? val?.celeb.identified_id
								: val?.user.identified_id,
						subscriber_nickname:
							data.user_type === userType.NORMAL ? val?.celeb.nickname : val?.user.nickname,
						subscriber_gender: data.user_type === userType.NORMAL ? val?.celeb.sex : val?.user.sex,
						start_date: val?.created_at_unix_timestamp
							? convertTimeZone(parseInt(val?.created_at_unix_timestamp))
							: "-",
						end_date: val?.expire_at_unix_timestamp
							? convertTimeZone(parseInt(val?.expire_at_unix_timestamp))
							: "-",
						status: val?.type ? convertTextToKorean(val?.type) : "",
					});
				});
			}
		} catch (error) {
		} finally {
			hideLoading();
			setItems(arr);
		}
	};

	useEffect(() => {
		fetchAll();
	}, [currentPage.value, LIMITED.value, filter.value]);
	useEffect(() => {
		currentPage.set(1);
	}, [filter.value]);

	return (
		<>
			<Stack spacing={2}>
				<div>
					<div
						style={{
							background: "#F6F6F6 0% 0% no-repeat padding-box",
							border: "1px solid #E6ECF3",
							height: "50px",
							padding: "13px 22px",
						}}
					>
						<p>구독 리스트</p>
					</div>
					<TheHeaderDefault
						Component={SupcriptionListFilter}
						typeHeadeFilter={keyHeaderFilter.SUPCRIPTION_LIST_USER_PROFILE}
						setFilter={filter.set}
						// cần sửa
					/>
					<DataTable
						columns={columns}
						total={total}
						items={items}
						page={spages.value}
						currentPage={currentPage.value}
						changePage={handleChangePage}
						limit={LIMITED}
						fileName="block user"
					/>
				</div>
			</Stack>
		</>
	);
};

export default UserInfo;
