import React, { useState, useEffect, useContext } from "react";
import "./style.css";
import Stack from "@mui/material/Stack";
import { LoadingContext } from "src/providers/LoadingProvider";

import TheHeaderDefault from "src/components/phase2/NewLayout/TheHeaderDefault";
import { TYPE_REVENUE, keyHeaderFilter } from "src/util/data";
import useFilters from "src/hooks/useFilter";
import { UseStarProfileApi } from "src/api";
import { convertTextToKorean, convertTimeZone } from "src/util/convert";

import DataTable from "src/components/phase2/DataTable";
import ListCoinFilter from "src/components/phase2/HeaderFilterInput/UserProfileListCoin";
import { Button, Grid } from "@mui/material";
import FilterInputWrapper from "../NewLayout/FilterInputWrapperV2";
import SelectInput from "src/components/CustomComponent/SelectInput";
import { showError, showSuccess } from "src/util/error";
import { RevenueApi } from "src/api";
const type = [
	{ title: "지급", value: TYPE_REVENUE.ADMIN_ADD_DONATE_MONEY },
	{ title: "차감", value: TYPE_REVENUE.ADMIN_SUB_DONATE_MONEY },
];
const UserInfo = ({ data }) => {
	const arr = [];
	const [items, setItems] = useState([]);
	const [total, settotal] = useState(0);
	const [typeAmount, setTypeAmount] = useState(null);
	const [amount, setAmount] = useState(null);
	const { showLoading, hideLoading } = useContext(LoadingContext);

	const { LIMITED, currentPage, spages, filter } = useFilters();
	const columns = [
		{
			field: "id",
			hide: true,
			headerAlign: "center",
			sortable: false,
		},
		{
			field: "no",
			headerName: "No",
			headerAlign: "center",
			flex: 1,
			sortable: false,
		},
		{
			field: "user_id",
			headerName: "회원 아이디",
			flex: 1,

			headerAlign: "center",
			sortable: false,
		},
		{
			field: "user_nickname",
			headerName: "회원 닉네임",
			flex: 1,

			headerAlign: "center",
			sortable: false,
		},
		{
			field: "reason",
			headerName: "사유",
			headerAlign: "center",
			flex: 1,

			sortable: false,
		},
		{
			field: "coin",
			headerName: "적립 금액",
			headerAlign: "center",
			flex: 1,

			sortable: false,
		},
		{
			field: "coin_after",
			headerName: "적립 후 금액",
			flex: 1,

			headerAlign: "center",
			sortable: false,
		},

		{
			field: "update_time",
			headerName: "변동날짜",
			headerAlign: "center",
			flex: 1,

			sortable: false,
		},
	];
	const handleChangePage = (e, value) => {
		currentPage.set(value);
	};

	const getAll = async () => {
		showLoading();
		try {
			const response = await UseStarProfileApi.getAll(
				currentPage.value,
				LIMITED.value,
				JSON.stringify({
					type: { $notIn: ["ADMIN_ADD_STAR", "ADMIN_SUB_STAR", "SUPCRIPTION", "SUBSCRIPTION"] },
					...filter.value,
					receiver_id: data.id,
				})
			);

			settotal(response?.results?.objects.count);
			if (response.code === 200) {
				// xu li pagination
				let pages = Math.max(Math.ceil(response.results.objects.count / LIMITED.value), 1);
				spages.set(pages);
				response.results.objects.rows.map((val, idx) => {
					return arr.push({
						id: val?.id,
						receiver_id: val?.receiver_id,
						no: idx + (currentPage.value - 1) * LIMITED.value + 1,
						user_id: val?.sender?.identified_id,
						user_nickname: val?.sender?.nickname,
						reason: convertTextToKorean(val?.type),
						coin: (val?.type == "SUBSCRIPTION" ? "-" : val?.total_fee * 200).toLocaleString(),
						coin_after: (val?.current_wallet_receiver || 0).toLocaleString(),
						update_time: convertTimeZone(+val?.created_at_unix_timestamp),
					});
				});
			}
		} catch (error) {
		} finally {
			hideLoading();
			setItems(arr);
		}
	};

	const handleChange = (e) => {
		setTypeAmount(e.target.value);
	};

	const handleAdminPaymentStar = async () => {
		if (typeAmount === TYPE_REVENUE.ADMIN_SUB_DONATE_MONEY && +amount >= data.donated_money) {
			showError(`차감될 금액이 소유하고 있는 금액과 같거나 적어야 합니다.`, "error", "오류");
			hideLoading();
			return;
		}
		try {
			const res = await RevenueApi.adminUpdateDonateMoney({
				amount,
				user_id: data?.id,
				type: typeAmount,
			});
			if (res.code === 200) {
				showSuccess();
				getAll();
			}
		} catch (error) {
			hideLoading();
			showError(error);
		}
	};

	useEffect(() => {
		getAll();
	}, [currentPage.value, LIMITED.value, filter.value, data]);
	useEffect(() => {
		currentPage.set(1);
	}, [filter.value]);

	return (
		<>
			<Stack spacing={2}>
				<div>
					<div
						style={{
							background: "#F6F6F6 0% 0% no-repeat padding-box",
							border: "1px solid #E6ECF3",
							height: "50px",
							padding: "13px 22px",
						}}
					>
						<p>코인 적립 리스트</p>
					</div>
					<TheHeaderDefault
						Component={ListCoinFilter}
						typeHeadeFilter={keyHeaderFilter.LIST_COIN_USER_PROFILE}
						setFilter={filter.set}
						initValue={{
							memberInfoSelect: "$sender.identified_id$",
							memberInfoInput: "",
							time__data: [],
							reason: "ALL",
						}}
						// cần sửa
					/>
					<DataTable
						columns={columns}
						total={total}
						items={items}
						page={spages.value}
						currentPage={currentPage.value}
						changePage={handleChangePage}
						limit={LIMITED}
						fileName="block user"
					/>
				</div>
				<div>
					<div
						style={{
							background: "#F6F6F6 0% 0% no-repeat padding-box",
							border: "1px solid #E6ECF3",
							height: "50px",
							padding: "13px 22px",
						}}
					>
						<p>코인지금</p>
					</div>
					<Grid
						container
						rowSpacing={1}
						sx={{ mt: 1, p: 2 }}
						columnSpacing={{ xs: 1, sm: 2, md: 3 }}
					>
						<Grid item xs={6}>
							{/* type */}
							<FilterInputWrapper label={"종류"}>
								<SelectInput
									data={type}
									value={typeAmount}
									className="select"
									handleChange={handleChange}
								/>
							</FilterInputWrapper>
						</Grid>

						<Grid item xs={6}>
							{/* STAR */}
							<FilterInputWrapper label={"코인"}>
								<input
									type="text"
									value={amount}
									onChange={(e) => setAmount(e.target.value)}
									className="custom-input"
								/>
							</FilterInputWrapper>
						</Grid>
					</Grid>
					<div style={{ display: "flex", justifyContent: "center" }}>
						<Button
							variant="contained"
							sx={{
								background: "#3B3B3B ",
								border: "1px solid #E1E1E1",
								borderRadius: "10px",
								width: "300px",
								height: "34px",
							}}
							onClick={handleAdminPaymentStar}
						>
							확인
						</Button>
					</div>
				</div>
			</Stack>
		</>
	);
};

export default UserInfo;
