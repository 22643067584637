import Stack from "@mui/material/Stack";
import { useContext, useEffect, useState } from "react";
import PluskitPackageListASPApi from "src/api/PluskitPackageListASPApi";
import DataTable from "src/components/phase2/DataTable";
import TheHeaderDefault from "src/components/phase2/NewLayout/TheHeaderDefault";
import useFilters from "src/hooks/useFilter";
import { LoadingContext } from "src/providers/LoadingProvider";
import { convertPluskitPurchaseStatus, convertTimeBirthday } from "src/util/convert";
import { keyHeaderFilter } from "src/util/data";
import "./style.css";
import PluskitPackageListASPFilter from "./filter/PluskitPackageListASPFilter";
import moment from "moment";
import { Button } from "@mui/material";

const PluskitPackageListASP = ({ data }) => {
    const [items, setItems] = useState([]);
    const [total, setTotal] = useState(0);
    const { showLoading, hideLoading } = useContext(LoadingContext);
    const { LIMITED, currentPage, spages, filter } = useFilters();
    const columns = [
        {
            field: "id",
            hide: true,
            headerAlign: "center",
            sortable: false,
        },
        {
            field: "no",
            headerName: "No",
            headerAlign: "center",
            sortable: false,
        },
        {
            field: "title",
            headerName: "구매",
            flex: 1,
            headerAlign: "center",
            sortable: false,
        },
        {
            field: "celeb_nickname",
            headerName: "셀럽닉네임(ID)",
            flex: 1,
            renderCell: (params) => {
                return <div style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                }}>
                    {params?.row?.type === "LIMITED" ?
                    <><p>{params?.row?.nickname}</p>
                    <p>({params?.row?.identified_id})</p></>
                    : <>모든 셀럽</>}
                    
                </div>;
            },
            headerAlign: "center",
            sortable: false,
        },
        {
            field: "purchase_date",
            headerName: "구매날짜",
            headerAlign: "center",
            flex: 1,

            sortable: false,
        },
        {
			field: "end_date",
			headerName: "종료날짜",
			headerAlign: "center",
			flex: 1,
            
			renderCell: (params) => {
				return (
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							textAlign: "center",
						}}
					>
						{params?.row?.purchase_status === "ADMIN_CANCELED" ? (
							<p style={{ color: "red" }}>{params?.row?.end_date}</p>
						) : (
							<p> {params?.row?.end_date}</p>
						)}
					</div>
				);
			},
			sortable: false,
		},
        {
            field: "remaining_period",
            headerName: "남은 기간",
            headerAlign: "center",
            flex: 1,

            sortable: false,
        },
        {
            field: "purchase_amount",
            headerName: "구매 금액",
            flex: 1,
            headerAlign: "center",
            sortable: false,
        },

        {
            field: "purchase_status",
            headerName: "구매 상태",
            headerAlign: "center",
            flex: 1,
            renderCell: (params) => {
                return <div>{convertPluskitPurchaseStatus(params?.row?.purchase_status)}</div>;
            },
            sortable: false,
        },
        {
            field: "note",
            headerName: "비고",
            headerAlign: "center",
            flex: 1,
            sortable: false,
            renderCell: (params) => {
                if (params.row.purchase_status === "SUBSCRIBED"
                    || params.row.purchase_status === "UPGRADED"
                )
                    return (
                        <Button
                            variant="outlined"
                            sx={{
                                background: "red",
                                width: 70,
                                height: 34,
                                borderRadius: "0.5rem",
                                color: "white",
                                borderColor: "red",
                                ':hover': {
                                    background: "red",
                                    opacity: 0.8
                                }
                            }}
                            onClick={() => handleCancel(params.row.id)}
                        >
                            취소
                        </Button>
                    );
                else
                    return <></>
            },
        },
    ];

    const handleChangePage = (e, value) => {
        currentPage.set(value);
    };

    const getRemainingPeriod = (endTime, startTime, updateTime, status) => {
        const endDay = moment(endTime).startOf('day');
        const today = moment().startOf('day');
        const diffDay = endDay.diff(today, 'days');
        if(status === 'ADMIN_CANCELED'){
            const endDay = moment(updateTime).startOf('day');
            const expirationDay = moment(startTime).add(30, "days").startOf("day");
            const diffDay = expirationDay.diff(endDay, "days");
            return diffDay > 0 ? diffDay : 0;
        }
        return diffDay > 0 ? diffDay : 0;
    }

    const handleCancel = async(packageId) => {
        showLoading();
        try {
            const payload = {
                status: 'ADMIN_CANCELED',
                expire_at_unix_timestamp: moment().valueOf()
            }

            const response = await PluskitPackageListASPApi.update(payload, packageId);
            if (response?.code === 200) {
                fetchAll();
            }
        } catch (error) {
        } finally {
            hideLoading();
        }
    }

    const fetchAll = async () => {
        showLoading();
        let tempItems = [];
        try {
            const response = await PluskitPackageListASPApi.getAll(
                currentPage.value,
                LIMITED.value,
                JSON.stringify({ ...filter.value, user_id: data?.id })
            );

            setTotal(response?.results?.objects.count);
            if (response.code === 200) {
                let pages = Math.max(Math.ceil(response.results.objects.count / LIMITED.value), 1);
                spages.set(pages);
                response.results.objects.rows.map((val, idx) => {
                    return tempItems.push({
                        id: val?.id,
                        no:
                            response?.results?.objects.count - (idx + (currentPage.value - 1) * LIMITED.value),
                        title: val?.pack_sub?.title,
                        nickname: val?.celeb?.nickname,
                        identified_id: val?.celeb?.identified_id,
                        purchase_date: convertTimeBirthday(Number(val?.register_at_unix_timestamp)),
                        end_date: convertTimeBirthday(Number(val?.expire_at_unix_timestamp)),
                        remaining_period: getRemainingPeriod(Number(val?.expire_at_unix_timestamp), Number(val?.register_at_unix_timestamp), Number(val?.updated_at_unix_timestamp), val?.status),
                        purchase_amount: val?.amount?.toLocaleString(),
                        purchase_status: val?.status || "-",
                        type: val?.pack_sub?.type,
                    });
                });
            }
        } catch (error) {
        } finally {
            hideLoading();
            setItems(tempItems);
        }
    };

    useEffect(() => {
        fetchAll();
    }, [currentPage.value, LIMITED.value, filter.value, data?.id]);

    useEffect(() => {
        currentPage.set(1);
    }, [filter.value, LIMITED.value]);

    return (
        <Stack spacing={2}>
            <div
                style={{
                    background: "#F6F6F6 0% 0% no-repeat padding-box",
                    border: "1px solid #E6ECF3",
                    height: "50px",
                    padding: "13px 22px",
                }}
            >
                <p>스타 내역</p>
            </div>
            <TheHeaderDefault
                Component={PluskitPackageListASPFilter}
                typeHeadeFilter={keyHeaderFilter.PLUSKIT_PACKAGE_LIST_ASP}
                setFilter={filter.set}
                initValue={{
                    celebInfoSelect: "$celeb.identified_id$",
                    celebInfoInput: "",
                    status: "ALL",
                    timeInfoSelect: "ALL",
                    type: "ALL",
                    time__data: []
                }}
            />
            <DataTable
                columns={columns}
                total={total}
                items={items}
                page={spages.value}
                currentPage={currentPage.value}
                changePage={handleChangePage}
                limit={LIMITED}
                fileName="pluskit package list ASP"
            />
        </Stack>
    );
};

export default PluskitPackageListASP;
