import { Stack } from "@mui/material";
import { Field } from "formik";
import React, { useState } from "react";
import DateRangePicker from "src/components/phase2/DateRangePicker";
import { SelectField, InputField } from "src/components/phase2/Fields";
import FilterInputWrapper from "src/components/phase2/NewLayout/FilterInputWrapper";

const GIFT_REGISTRATION_STATUS = {
	REGISTER: "REGISTER",
	DELETE: "DELETE",
};

function Filter() {
	const [DateRange, setDateRange] = useState([]);

	const typeDateOptions = [
		{
			value: "created_at_unix_timestamp",
			label: "등록날짜",
		},
		{
			value: "updated_at_unix_timestamp",
			label: "삭제날짜",
		},
	];

	const statusGifts = [
		{
			value: "ALL",
			label: "전체",
		},
		{
			value: GIFT_REGISTRATION_STATUS.REGISTER,
			label: "등록",
		},
		{
			value: GIFT_REGISTRATION_STATUS.DELETE,
			label: "삭제",
		},
	];

	return (
		<Stack gap={2}>
			<Stack direction="row" gap={5}>
				<div style={{ width: "50%" }}>
					{/* Status gift */}
					<FilterInputWrapper
						label="상태"
						style_label={{
							width: "auto",
							minWidth: "120px",
						}}
						styleBody={{
							width: "auto",
							minWidth: "250px",
						}}
						styleFilter={{
							justifyContent: "flex-start",
							marginBottom: 0,
						}}
					>
						<Field
							name="statusGift"
							value={statusGifts[0].value}
							component={SelectField}
							data={statusGifts}
						/>
					</FilterInputWrapper>
				</div>

				{/* User name */}
				<FilterInputWrapper
					label="처리계정"
					style_label={{
						width: "auto",
						minWidth: "120px",
					}}
					styleBody={{
						minWidth: "250px",
						width: "100%",
						flex: 1,
						fontSize: "16px",
					}}
					styleFilter={{
						justifyContent: "flex-start",
						marginBottom: 0,
					}}
				>
					<Field
						name="username"
						component={InputField}
						placeholder="입력해주세요"
						className="inputFilter"
					/>
				</FilterInputWrapper>
			</Stack>
			<Stack direction="row" gap={5}>
				<div style={{ width: "50%" }}>
					<div
						className="filterInput"
						style={{
							marginBottom: 0,
						}}
					>
						<div
							className="filterInput__label"
							style={{
								width: "auto",
								minWidth: "120px",
							}}
						>
							<label>기간검색</label>
						</div>
						<div
							className="filterInput__body"
							style={{
								width: "auto",
								marginRight: "auto",
								gap: "10px",
							}}
						>
							<Field
								value={typeDateOptions[0]?.value}
								name="dateTypeSelect"
								component={SelectField}
								data={typeDateOptions}
							/>
							<Field name="time__data">
								{({ field, form }) => {
									return (
										<DateRangePicker
											DateRange={DateRange}
											setDateRange={setDateRange}
											name={field.name}
											form={form}
										/>
									);
								}}
							</Field>
						</div>
					</div>
				</div>
			</Stack>
		</Stack>
	);
}

export default React.memo(Filter);
