import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import { Field } from "formik";
import { useState } from "react";
import { headerFilter } from "src/util/data";
import { DataRangePickerField, InputField, SelectField } from "../Fields";
import FilterInputWrapper from "../NewLayout/FilterInputWrapper";
import "../style.css";

const ManagepostCeleb = () => {
	const [DateRange, setDateRange] = useState([]);
	const { memberInfo2, status, genderArr, searchTime2 } =
		headerFilter.ListFollowUserProfile;

	return (
		<FormGroup>
			<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
				<Grid item xs={6}>
					{/* type subscribe */}
          {/* Request #278 - Remove type_subscriber */}
					{/* <FilterInputWrapper label="구독종류">
						<Field name="type_subcriber" component={SelectField} data={type_subscriber} />
					</FilterInputWrapper> */}
					{/* status */}
					<FilterInputWrapper label="구독여부">
						<Field name="status" component={SelectField} data={status} />
					</FilterInputWrapper>
					{/* time */}
					<FilterInputWrapper label="기간검색">
						<Field name="search_time" component={SelectField} data={searchTime2} />
						<div style={{ marginRight: "7px" }}></div>

						<Field
							name="time__data"
							component={DataRangePickerField}
							DateRange={DateRange}
							setDateRange={setDateRange}
						/>
					</FilterInputWrapper>
				</Grid>
				<Grid item xs={6}>
					{/* member info */}
					<FilterInputWrapper label=" 셀럽정보">
						<Grid item xs={4} className="mr-2">
							<Field name="memberInfoSelect" component={SelectField} data={memberInfo2} />
						</Grid>
						<Grid item xs={8}>
							<Field
								name="memberInfoInput"
								component={InputField}
								placeholder="입력해주세요"
								className="inputFilter"
							/>
						</Grid>
					</FilterInputWrapper>
					{/* gender */}
					<FilterInputWrapper label="성별">
						<Field name="gender" component={SelectField} data={genderArr} />
					</FilterInputWrapper>
				</Grid>
			</Grid>
		</FormGroup>
	);
};

export default ManagepostCeleb;
